import { getDate, getConvertDate } from "@/assets/js/function";
const date = getDate('mon');
/**
 * @description 알람 관리 모듈
 * @author 김원명, @date 2023-12-27
 */
const alarmModule = {
    namespaced: true,
    state: {
        alarm : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd'),
            },
            filters : [
                { title : '기관', count : 0 },
                { title : '강사', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : [],
            info    : getAlarmInfo()
        }
    },
    mutations: {
        setFilters(state, newFilters)
        {
            state.alarm.filters = newFilters;   
        },
        setList(state, newList) 
        {
            state.alarm.list = newList;
        },
        setAllList(state, newAllList) 
        {
            state.alarm.allList = newAllList;
            state.alarm.list    = newAllList;
        },
        setInfo(state, newInfo)
        {
            state.alarm.info = newInfo;
        },
        setReset(state)
        {
            state.alarm.info = getAlarmInfo();
        }
    }
};

function getAlarmInfo()
{
    return {
        subject  : '',
        userGb   : { value : 'A', name : '전체' },
        contents : '',
        memo     : ''
    }
}

export default alarmModule;