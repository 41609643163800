import { getAxiosData, getCommas } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 강사 리스트
 */
export async function getTeacherMemMngList(params)
{
    const router = useRouter();

    try 
    {
        let normal  = 0;
        let out     = 0;
        let draw    = 0;

        const list = [];
        const res  = await getAxiosData("https://data.wecls.com/admin/instList/getList", params);

        console.log(res);

        /** 카운트 헤더 */
        const headers = [
            { title : '이번달 신규가입', count : res.data['cnt']['newInst']+'명' },
            { title : '이번달 탈퇴', count : res.data['cnt']['outInst']+'명' },
            { title : '강사 업데이트 대기', count : res.data['cnt']['unReg']+'건' },
            { title : '강사 업데이트 심사중', count : res.data['cnt']['reging']+'건' },
            { title : '강사 업데이트 승인', count : res.data['cnt']['reged']+'건' }
        ];

        store.commit("teacherMemMng/setHeaders", headers);

        let gbNm;
        let stNm;

        res.data['list'].map((item) => {
            switch(item.activeGbNm)
            {
                case '활동':
                    gbNm = '일반회원';
                    normal++;
                break;
                case '정지':
                    gbNm = '정지회원';
                    out++;
                break;
                case '탈퇴':
                    gbNm = '탈퇴회원';
                    draw++;
                break;
            }

            if(item.state)
            {
                switch(item.state)
                {
                    case 'E':
                        stNm = '신청';
                    break;
                    case 'Y':
                        stNm = '승인완료';
                    break;
                    case 'N':
                        stNm = '승인불가';
                    break;
                }
            }
            else
            {
                stNm = '미신청';
            }

            list.push({
                iaCd            : item.iaCd,
                platform        : item.platform,
                email           : item.id,
                channel         : item.channel === 'D' ? '일반' : (item.channel === 'K' ? '카카오' : '네이버'),
                name            : item.name,
                activeGbNm      : item.activeGbNm,
                gbNm            : gbNm,
                stNm            : stNm,
                modDt           : item.modDt ? item.modDt.slice(0, -9) : '',
                regDt           : item.regDt ? item.regDt.slice(0, -9) : '',
                recLogin        : item.recLogin,
                loginCnt        : item.loginCnt+'회'
            });
        });

        const filters = [
            { title : '일반회원', count : normal },
            { title : '정지회원', count : out },
            { title : '탈퇴회원', count : draw },
            { title : '전체', count : list.length }
        ];

        store.commit('teacherMemMng/setFilters', filters);
        store.commit("teacherMemMng/setAllList", list);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 강사 정보
 */
export async function getTeacherMemInfo(params)
{
    const router = useRouter();
    
    try
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/instList/getInfo", params);
        const info = res.data['info'];

        console.log(res);

        let agree       = info['markAgree'];
        let resultArray = [];
        
        for (let key in agree) 
        {
            switch (agree[key]) 
            {
                case 'Y':
                    switch (key) 
                    {
                        case 'app':
                            resultArray.push('앱 푸시');
                        break;
                        case 'email':
                            resultArray.push('Email');
                        break;
                        case 'kakao':
                            resultArray.push('카카오 알림톡');
                        break;
                        case 'sms':
                            resultArray.push('SMS');
                        break;
                    }
                break;
            }
        }

        const fileOther = [];

        if(info['etcFile'] !== null)
        {
            info['etcFile'].map((item) => {
                fileOther.push({
                    text : item.fileNm,
                    file : item.filePath
                });
            })
        }

        const dayOrder = {
            '월': 1,
            '화': 2,
            '수': 3,
            '목': 4,
            '금': 5,
            '토': 6,
            '일': 7,
        };
        
        const dayList = info['prefDay'] !== "" ? info['prefDay'].sort((a, b) => dayOrder[a] - dayOrder[b]) : info['prefDay'];

        const data = {
            itCd            : info['itCd'],
            platform        : '강사',
            channel         : info['channel'] === 'D' ? '일반' : ( info['channel'] === 'K' ? '카카오' : '네이버' ),
            joinDt          : info['regDt'],
            loginDt         : info['recLogin'],
            id              : info['id'],
            name            : info['name'],
            tel             : info['tel'],
            birthday        : info['birth'],
            gender          : info['gender'],
            agree           : resultArray.join(' / '),
            appeal          : info['appeal'],
            idPic           : info['idPic'] ? info['idPic']['filePath'] : null, /** 증명 사진 */
            emerTel         : info['emerTel'],
            desArea1        : info['desArea1'],
            prefDay         : dayList.join(', '),
            acadAbil        : info['acadAbil'],
            school          : info['school'],
            major           : info['major'],
            mainFieldCd     : info['mainFieldCd'],
            fieldCd         : info['fieldCd'],
            career          : info['career'],
            repCareer       : info['repCareer'],
            finalEdu        : info['finalEdu'] ? info['finalEdu']['filePath'] : null, /** 최종학력 증명 파일*/
            careerFile      : info['careerFile'] ? info['careerFile']['filePath'] : null, /** 경력 증명서 파일 */
            fileOther       : fileOther,
            pubYn           : info['pubYn'] === 'Y' ? '동의' : '미동의',
            lcCnt           : info['lcCnt'],
            volCnt          : info['volCnt'],
            matchCnt        : info['matchCnt'],
            compCnt         : info['compCnt'],
            compAmt         : getCommas(info['compAmt']),
            cancleCnt       : info['cancleCnt'],
            userMemo        : res.data['userMemo'],
            state           : info['state'],
            lastDt          : info['lastDt']
        }

        store.commit('teacherMemMng/setInfo', data);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}