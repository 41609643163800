<template>
    <div class="inner box">
        <div class="box-title">
            <h2>알림 발송</h2>
            <RoundLink linkText="알림 등록하기" href="/alarmUpdate" />
        </div>

        <FilterList :filters="filters" @filter-changed="getListChange"/>

        <div class="grey-box margin">
            <SelectBox :labelText="'간편 조회'" :selectOptions="dateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>
        <BasicTable :columns="tableList" :rows="list" class="margin" @row-click="getDetail"/>
    </div>
</template>

<script>
import RoundLink from "@/components/button/RoundLink.vue";
import FilterList from "@/components/list/FilterList.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import DateBox from "@/components/input/DateBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";

import { getAlarmList } from "@/assets/js/alarm";
import { mapState } from "vuex";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name      : "AlarmList",
    components: {BasicTable, DateBox, SelectBox, FilterList, RoundLink},
    computed: {
        ...mapState('data', {
            dateType : state => state.data.dateType
        }),
        ...mapState('alarm',{
            filters : state => state.alarm.filters,
            search  : state => state.alarm.search,
            allList : state => state.alarm.allList,
            list    : state => state.alarm.list
        })
    },
    data() {
        return {
            tableList : [
                {key: "num", header: "번호", class: "w6"},
                {key: "subject", header: "제목", class: "blue"},
                {key: "userGb", header: "구분", class: "w9"},
                {key: "gb", header: "발송 구분", class: "w7"},
                {key: "regDt", header: "발송일시", class: "w12"},
            ]
        }
    },
    created () {
        this.getList();
    },
    methods : {
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            this.getList();
        },
        
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            this.getList();
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            this.getList();
        },
        getDetail(params){
            this.$router.push({ path: `/alarmUpdate/${params['notiCd']}` });
        },
        getList(){
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getAlarmList(params);
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('alarm/setList', this.allList);
            }
            else
            {
                const list = this.allList.map((item, index) => {
                    if (item.userGb === params['title']) 
                    {
                        return {
                            ...item,
                            num : index + 1,  
                        };
                    }
                }).filter(Boolean);

                this.$store.commit('alarm/setList', list);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}

.grey-box {
    padding: 20px 10px;
    position: relative;
    display: flex;

    div + div {
        margin-left: 10px;
    }

    .rmdeium {
        @include my-auto;
        right: 10px;
    }
}
</style>