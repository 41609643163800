<template>
    <div class="notice" :class="noticeClass">
        <div class="notice-wrapper">
            <h2 v-if="showTitle" class="notice-title">
                {{ title }}
            </h2>
            <h3 v-if="showSubTitle" class="notice-sub-title">
                {{ subTitle }}
            </h3>
            <h4 v-if="showSimpleTitle" class="notice-simple-title">
                {{ simpleTitle }}
            </h4>
            <button v-if="showButton">{{ buttonText }}</button>
        </div>
        <ul class="notice-box" v-if="!showNotice">
            <li v-for="(list, index) in lists" :key="index" :class="list.class">
                {{ list.content }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name    : "NoticeBox",
    props   : {
        title          : String,
        subTitle       : String,
        simpleTitle    : String,
        lists          : Array,
        showTitle      : Boolean,
        showSubTitle   : Boolean,
        showSimpleTitle: Boolean,
        showButton     : Boolean,
        buttonText     : String,
        showNotice     : Boolean,
    },
    computed: {
        noticeClass() {
            return {
                'no-list': this.style === 'no-list',
                'blue2'  : this.style === 'blue2',
                'blue3'  : this.style === 'blue3',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.notice {
    .notice-wrapper {
        position: relative;

        button {
            border-radius: 30px;
            @include my-auto;
            right: 0;
            background-color: $blue-color-lv-4;
            color: $bk-color-lv-3;
            padding: 16px 26px;
            font-size: $font16;
            font-weight: 700;
        }

        .notice-title {
            font-size: $font40;
            font-weight: 700;
            margin-bottom: 46px;
        }

        .notice-sub-title {
            font-size: $font28;
            font-weight: 700;
            margin-bottom: 46px;
        }

        .notice-simple-title{
            font-size: $font16;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    .notice-box {
        width: 100%;
        padding: 20px;
        border-radius: 4px;
        background-color: $bk-color-lv-2;
        font-size: $font14;
        color: $text-color-lv-0;
        font-weight: 500;

        li {
            list-style: disc;
            margin-left: 20px;

            &.mb{
                margin-bottom: 30px;
            }
        }
    }

    &.no-list {
        .notice-box {
            li {
                list-style: none;
                margin-left: 0;
            }
        }
    }

    &.blue2 {
        .notice-box {
            background-color: $blue-color-lv-2;
        }
    }

    &.blue3 {
        .notice-box {
            background-color: $blue-color-lv-3;
        }
    }
}
</style>