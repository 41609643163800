<template>
    <div class="side" :class="{ 'active' : menuOpen }">
        <div class="side-header">
            <h3>로고</h3>
            <img src="@/assets/images/icon-menu.svg" alt="toggle" title="toggle" @click="toggleMenu"/>
        </div>

        <ul>
            <li v-for="(side, index) in sides" :key="index" :class="{ 'active' : isLinkActive(side.to) }">
                <router-link :to="side.to">
                    <span>
                        {{ side.name }}
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "SideHeader",
    data() {
        return {
            sides: [
                {to: "/agencyMemMng", name: "기관 회원관리"},
                {to: "/teacherMemMng", name: "강사 회원관리"},
                {to: "/announceMng", name: "공고 현황"},
                {to: "/calcMng", name: "정산 현황"},
                {to: "/noticeList", name: "공지사항"},
                {to: "/contactList", name: "문의하기"},
                {to: "/alarmList", name: "알림발송"},
                {to: "/tosList", name: "이용약관"},
                {to: "/adverList", name: "광고관리"},
            ],
            menuOpen: true,
        }
    },
    methods: {
        isLinkActive(to) {
            return this.$route.path === to;
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        }
    }

}
</script>

<style lang="scss" scoped>
.side{
    position: fixed;
    top: 0;
    left: 0;
    background-color: $bk-color-lv-2;
    padding: 19px 22px;
    height: 100vh;
    z-index: 10;
    transition: width .3s ease;
    width: 240px;

    &.active{
        width: 240px;
    }

    &-header{
        font-size: $font18;
        font-weight: 700;
        position: relative;
        margin-bottom: 25px;

        img{
            @include my-auto;
            right: 0;
            cursor: pointer;
        }
    }

    ul{
        li{
            font-size: $font14;
            font-weight: 500;

            &:hover, &.active{
                a{
                    span{
                        box-shadow: 0 -3px 0 0 $blue-color-lv-4 inset;
                    }
                }
            }

            a{
                display: block;

                span{
                    display: inline-block;
                    padding: 12px 0;
                    transition: all .2s ease;
                }
            }
        }
    }
}
</style>