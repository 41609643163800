<template>
    <div class="inner box">
        <h2 class="notice-title">
            정산신청
        </h2>

        <LabelTable title="상품정보" class="mt-50 no-shadow">
            <tr v-for="(item, index) in instInfo['lcInfo']" :key="index">
                <th>{{ index + 1 }}</th>
                <td>
                    <img :src="`${downloadPath}/${item['filePath']}`"/>
                    <p>{{ item['subject'] }}</p>
                </td>
            </tr>
        </LabelTable>
        <LabelTable title="입금 정보" class="no-shadow">
            <tr>
                <th>정산방법</th>
                <td>{{ instInfo['calcGb'] }}</td>
            </tr>

            <tr>
                <th>상품총액</th>
                <td><b>{{ getCommas(instInfo['totalAmt']) }}원</b></td>
            </tr>

            <tr>
                <th>매칭 수수료</th>
                <td>{{ getCommas(instInfo['chargeAmt']) }}원 ({{ instInfo['charge'] }}%)</td>
            </tr>

            <tr>
                <th>정산금액</th>
                <td><b>{{ getCommas(instInfo['calcAmt']) }}원</b></td>
            </tr>

            <tr>
                <th>결제방법</th>
                <td>
                    <div>계좌이체</div>
                    <div>
                        <p class="w100">입금 은행명</p>
                        <p>{{ instInfo['bankNm'] }}</p>
                    </div>
                    <div>
                        <p class="w100">입금자명</p>
                        <p>{{ instInfo['bankAcc'] }}</p>
                    </div>
                    <div>
                        <p class="w100">입금 계좌번호</p>
                        <p>{{ instInfo['bankNum'] }}</p>
                    </div>
                    <div>
                        <ul>
                            <li>정산 신청시 정산 기준은 기관의 결제일에 따라 정해집니다.</li>
                            <li>데이페이를 선택한 경우, 기관 결제 유무에 상관없이 정산요청일 영업일 24시간 이내에 정산이 완료됩니다.</li>
                            <li>데이페이를 통한 신속한 정산으로 편리하게 이용하실 수 있습니다.</li>
                        </ul>
                    </div>
                </td>
            </tr>
        </LabelTable>
        <LabelTable title="서류 신청" class="no-shadow">
            <tr>
                <th>증빙서류</th>
                <td>
                    <div>{{ instInfo['docEnum'] }}</div>
                    <template v-if="instInfo['docEnum'] === '세금계산서'">
                        <div>
                            <ul>
                                <li>세금계산서, 현금영수증은 중복 신청 및 발급 이후 변경이 불가능하니 신중하게 선택해주세요.</li>
                                <li>방급에 문제가 있는 경우 'FAQ-세금계산서' 항목을 참고해주세요.</li>
                            </ul>
                        </div>
                        <div>
                            <p class="w100">사업자명</p>
                            <p>{{ instInfo['einNm'] }}</p>
                        </div>
                        <div>
                            <p class="w100">대표자명</p>
                            <p>{{ instInfo['ceoNm'] }}</p>
                        </div>
                        <div>
                            <p class="w100">사업자등록번호</p>
                            <p>{{ instInfo['einNum'] }}</p>
                        </div>
                        <div>
                            <p class="w100">업태</p>
                            <p>{{ instInfo['einType'] }}</p>
                        </div>
                        <div>
                            <p class="w100">종목</p>
                            <p>{{ instInfo['einItem'] }}</p>
                        </div>
                        <div>
                            <p class="w100">주소</p>
                            <p>{{ instInfo['addr'] }}</p>
                        </div>
                        <div>
                            <p class="w100">이메일주소</p>
                            <p>{{ instInfo['email'] }}</p>
                        </div>
                    </template>
                    <div v-if="instInfo['docEnum'] !== '세금계산서'">
                        <ul>
                            <li>사업소득세는 원천징수영수증 발급이 가능하며, 발급받을 이메일 주소를 기재해야 합니다.</li>
                            <li>위클즈는 강사 개인의 종합소득세 신고는 대신하지 않으니, 발생 소득에 대한 신고는 개인적으로 진행하셔야 합니다.</li>
                        </ul>
                    </div>
                </td>
            </tr>
        </LabelTable>

        <div class="button-wrapper">
            <BaseButton class="grey large" buttonText="목록이동" @click="getBack"/>
            <BaseButton v-if="instInfo['adminYn'] !== 'Y'" class="large" buttonText="지급완료 처리" @click="getResultSet"/>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getCalcMngInstInfo } from "@/assets/js/calcMng";
import { getAxiosData, getCommas } from "@/assets/js/function";

export default {
    name: "CalcDetail",
    components: {BaseButton, LabelTable, Confirm},
    data() {
        return {
            resultYn            : 'N',
            compYn              : 'N',
            mtCd                : this.$route.params.mtCd,
            downloadPath        : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : ''
        }
    },
    created() {
        this.getInfo();
    },
    computed: {
        ...mapState('calcMng', {
            instInfo  : state => state.calcMng.instInfo
        })
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                this.$router.go(-1);
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInfo() {
            const params = {
                mtCd : this.mtCd
            };

            getCalcMngInstInfo(params);
        },
        getCommas(value) {
            return getCommas(Number(value));
        },
        getBack() {
            this.$router.go(-1);
        },
        getResultSet() {
            this.getConfirmSet('confirm', '지급', '지급완료 처리하시겠습니까?');
            this.resultYn = 'Y';
        },
        async getResult() {
            try
            {
                const params = {
                    mtCd        : this.mtCd,
                    state       : 'Y'
                }

                console.log(params);

                const res = await getAxiosData('https://data.wecls.com/admin/calcList/instState', params);

                console.log(res);
                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '완료', '처리되었습니다.');
                }
            }
            catch(error)
            {
                console.error(error);
                if(error.response.data.status === 401)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout', 'inst');
                    this.$router.push('/login');
                }
                else
                {
                    this.getConfirmSet('alert', '에러', '에러가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    .margin {
        margin-top: 46px;
    }

    .notice-title {
        font-size: $font28;
        font-weight: 700;
        margin-bottom: 46px;
    }

    table{
        tr{
            td{
                img{
                    width: 78px;
                    height: 87px;
                    border-radius: 6px;
                    margin: 9px 20px 9px 16px;
                    vertical-align: middle;
                    display: inline-block;
                }
                p{
                    display: inline-block;
                    vertical-align: middle;
                    &.w100{
                        width: 100px;
                        margin-right: 10px;
                    }
                }

                div + div {
                    margin-top: 10px;
                }

                ul{
                    li{
                        color: $text-color-lv-5;
                        list-style: disc;
                        margin-left: 25px;
                    }
                }
            }
        }
    }

    button.w240{
        margin: 40px auto 0 auto;
        padding: 12px;
        width: 240px;
        text-align: center;
        display: block;
        font-size: $font20;
    }
}

.button-wrapper{
    text-align: center;
    margin-top: 80px;
}
</style>