import { getAxiosData, getCommas } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 공고 리스트
 */
export async function getAnnounceMngList(params)
{
    const router = useRouter();

    try 
    {
        const list = [];
        const res  = await getAxiosData("https://data.wecls.com/admin/lectList/getList", params);

        console.log(res);

        let gbNm;

        let wait   = 0;
        let update = 0
        let ing    = 0;
        let comp   = 0;
        let turn   = 0;

        res.data['list'].map((item) => {
            if(item.matchSt === 'Y')
            {
                gbNm = '매칭완료';
                comp++;
            }
            else
            {
                switch(item.state)
                {
                    case 'E':
                        gbNm = '공고대기';
                        wait++;
                    break;
                    case 'M':
                        gbNm = '수정대기';
                        update++;
                    break;
                    case 'N':
                        gbNm = '공고반려';
                        turn++;
                    break;
                    case 'Y':
                        gbNm = '공고진행';
                        ing++;
                    break;
                }
            }

            list.push({
                lcCd            : item.lcCd,
                subject         : item.subject,
                gbNm            : gbNm,
                ogNm            : item.ogNm,
                instNm          : item.instNm,
                regDt           : item.regDt.slice(0, -3),
                lectPeriod      : item.lectPeriod,
                volCnt          : item.volCnt+'명',
                amt             : getCommas(Number(item.amt)),
                state           : item.state
            });
        });

        console.log(list);

        const filters = [
            { title : '공고대기', count : wait },
            { title : '수정대기', count : update },
            { title : '공고진행', count : ing },
            { title : '공고반려', count : turn },
            { title : '매칭완료', count : comp },
            { title : '전체', count : list.length }
        ];

        store.commit('announceMng/setFilters', filters);
        store.commit('announceMng/setAllList', list);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 기관 정보
 */
export async function getAnnounceMngInfo(params)
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/lectList/getInfo", params);
        console.log(res);
        const info = res.data;

        const data = {
            lcCdOri     : info['lcCdOri'],
            fileNm      : info['fileNm'],
            subject     : info['subject'],
            dDay        : '',
            gubun       : '',
            regDt       : info['regDt'],
            mainFieldCd : info['mainFieldCd'],
            fieldCd     : info['fieldCd'],
            lectPeriod  : info['lectPeriod'],
            lectTime    : info['lectTime'],
            totalTime   : info['totalTime'],
            amt         : getCommas(Number(info['amt'])),
            estAmt      : getCommas(Number(info['estAmt'])),
            area1       : info['area1'],
            area2       : info['area2'],
            target      : info['target'],
            addr        : info['addr'] + ' ' + info['addrDetail'],
            lectEq      : info['lectEq'],
            keyword     : info['keyword'],
            regPeriod   : info['regPeriod'],
            lectMemo    : info['lectMemo'],
            reqDoc      : info['reqDoc'],
            etcDoc      : info['etcDoc'],
            imgList     : info['imgList'],
            fileList    : info['fileList'],
            ogNm        : info['ogNm'],
            ogAddr      : info['ogAddr'],
            chEmail     : info['chEmail'],
            chPerNm     : info['chPerNm'],
            chPerTel    : info['chPerTel']
        }

        store.commit('announceMng/setInfo', data);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}