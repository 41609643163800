<template>
    <div class="header">
        <div class="inner">
            <h1 class="logo">
                <router-link to="/">
                    <img src="@/assets/images/logo.png" alt="위클즈" title="위클즈" />
                </router-link>
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "TopHeader"
}
</script>

<style lang="scss" scoped>
.header {
    padding: 15px 0;
    position: fixed;
    left: 240px;
    top: 0;
    width: calc(100% - 240px);
    z-index: 9;
    background: #FBFBFD;

    .logo {
        display: inline-block;
        font-size: $font24;
        font-weight: 700;
        vertical-align: middle;

        a{
            display: flex;
            align-items: center;

            img{
                width: 70px;
            }
        }
    }
}
</style>