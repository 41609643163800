import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 강사 회원관리
 * @author 김원명, @date 2023-12-26
 */
const announceMngModule = {
    namespaced  : true,
    state       : {
        announceMng : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd')
            },
            filters : [
                { title : '공고대기', count : 0 },
                { title : '수정대기', count : 0 },
                { title : '공고진행', count : 0 },
                { title : '공고반려', count : 0 },
                { title : '매칭완료', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : [],
            info    : getAnnounceMngInfo()
        }
    },
    mutations: {
        setFilters(state, newFilters)
        {
            state.announceMng.filters = newFilters;
        },
        setAllList(state, newAllList)
        {
            state.announceMng.allList = newAllList;
            state.announceMng.list    = newAllList;
        },
        setList(state, newList)
        {
            state.announceMng.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.announceMng.info = newInfo;
        }
    }
};

function getAnnounceMngInfo()
{
    return {
        lcCdOri     : '',
        fileNm      : '',
        subject     : '',
        dDay        : '',
        gubun       : '',
        regDt       : '',
        fieldCd     : '',
        lectPeriod  : '',
        lectTime    : '',
        totalTime   : '',
        amt         : '',
        estAmt      : '',
        area1       : '',
        area2       : '',
        target      : '',
        addr        : '',
        lectEq      : '',
        keyword     : '',
        regPeriod   : '',
        lectMemo    : '',
        reqDoc      : '',
        etcDoc      : '',
        fileList    : [],
        ogNm        : '',
        ogAddr      : '',
        chEmail     : '',
        chPerNm     : '',
        chPerTel    : ''
    }
}

export default announceMngModule;
