import { getDate, getConvertDate } from "@/assets/js/function";
const date = getDate('mon');
/**
 * @description 광고관리 모듈
 * @author 김원명, @date 2024-01-12
 */
const adverModule = {
    namespaced: true,
    state: {
        adver : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd'),
            },
            list    : [],
            type    : 'in',
            info    : getAdverInfo()
        }
    },
    mutations: {
        setList(state, newList) 
        {
            state.adver.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.adver.info = newInfo;
        },
        setReset(state)
        {
            state.adver.type = 'in';
            state.adver.info = getAdverInfo();
        }
    }
};

function getAdverInfo()
{
    return {
        name    : '',
        url     : '',
        memo    : '',
        img     : {
            text    : '',
            file    : null
        }
    }
}

export default adverModule;