<template>
    <div class="inner box">
        <h2>정산 현황</h2>
        <FilterList :filters="filters" @filter-changed="getListChange"/>
        <div class="margin grey-box">
            <SelectBox :labelText="'간편 조회'" :selectOptions="dateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />
            <SelectBox :labelText="'입금 조회'" :selectOptions="dep" :selectId="'dep'" :selectVal="search['dep']" :selectChange="getSelectSet" class="small" />
            <SelectBox :labelText="'지급 조회'" :selectOptions="pay" :selectId="'pay'" :selectVal="search['pay']" :selectChange="getSelectSet" class="small" />

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>
        <BasicTable :columns="tableList" :rows="list" class="margin" @button-click="getButtonClick" @row-click="getListClick"/>
    </div>
</template>

<script>
import FilterList from "@/components/list/FilterList.vue";
import DateBox from "@/components/input/DateBox.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";

import { mapState } from "vuex";
import { getCalcMngList } from "@/assets/js/calcMng";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name      : "CalcMng",
    components: {BasicTable, SelectBox, DateBox, FilterList},
    computed: {
        ...mapState('data', {
            dateType  : state => state.data.dateType,
            dep       : state => state.data.dep,
            pay       : state => state.data.pay
        }),
        ...mapState('calcMng', {
            filters : state => state.calcMng.filters,
            search  : state => state.calcMng.search,
            allList : state => state.calcMng.allList,
            list    : state => state.calcMng.list
        })
    },
    created() {
        getCalcMngList(this.getParams());
    },
    data() {
        return {
            tableList : [
                {key: "subject", header: "강의명", class: "blue"},
                {key: "state", header: "강의상태", class: "w7"},
                {key: "ogNm", header: "기관명", class: "w13"},
                {key: "name", header: "강사명", class: "w8"},
                {key: "regDt", header: "공고 일시", class: "w10"},
                {key: "lectPeriod", header: "매칭 수업일자", class: "w11"},
                {key: "organ", header: "기관입금", class: "w9 blue", type : { gubun : 'button', text : '입금' }},
                {key: "inst", header: "강사지급", class: "w9 blue", type : { gubun : 'button', text : '지급' }},
                {key: "dayPay", header: "데이페이", class: "w7"},
                {key: "amt", header: "강의금액", class: "w8"}
            ]
        }
    },
    methods : {
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            getCalcMngList(this.getParams());
        },
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            getCalcMngList(this.getParams());
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            getCalcMngList(this.getParams());
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('calcMng/setList', this.allList);
            }
            else
            {
                let list = [];

                if (params['title'] === '입금대기') 
                {
                    list = this.allList.filter(item => item.organ === 'E' || item.organ === '입금대기');
                } 
                else if (params['title'] === '지급대기') 
                {
                    list = this.allList.filter(item => item.inst === 'E' || item.inst === '지급대기');
                } 
                else if (params['title'] === '정산완료') 
                {
                    list = this.allList.filter(item => item.organAdmin === 'Y' && item.instAdmin === 'Y');
                }

                this.$store.commit('calcMng/setList', list);
            }
        },
        getButtonClick(params, text) {
            if(text === '입금')
            {
                this.$router.push({ path : `/calcOrganDetail/${params['mtCd']}/${params['ogCd']}`});
            }
            else
            {
                this.$router.push({ path : `/calcInstDetail/${params['mtCd']}`});
            }
        },
        getListClick(params, key) {
            console.log(params);
            console.log(key);

            switch(key)
            {
                case 'subject':
                    this.$router.push({ name: 'AnnounceDetail', query: { lcCd: params['lcCd'], state: params['lcState'] } });
                break;
                case 'organ':
                    if(params['organAdmin'] === 'Y')
                    {
                        this.$router.push({ path : `/calcOrganDetail/${params['mtCd']}/${params['ogCd']}`});
                    }
                break;
                case 'inst':
                    if(params['instAdmin'] === 'Y')
                    {
                        this.$router.push({ path : `/calcInstDetail/${params['mtCd']}`});
                    }
                break;
            }
        },
        getParams(){
            return {
                startDt    : this.search['startDt'],
                endDt      : this.search['endDt'],
                instAdmin  : this.search['dep']['value'],
                organAdmin : this.search['pay']['value'],
                start      : 0,
                limit      : 50
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}
</style>