<template>
    <div class="input { 'error' : state === 'error' }" :class="inputClass">
        <label :for="inputId">
            {{ labelText }}
            <span v-if="important">*</span>
        </label>
        <div class="input-wrapper">
            <input :style="{ padding: iconSearch ? '10px 10px 10px 35px' : '0 10px' }" :id="inputId" :name="inputNm"
                   :type="inputType" :value="inputValue" :placeholder="placeholder" :disabled="disabled"
                   :readonly="readonly" :maxlength="maxLength" @input="handleInput($event)"
                   @focus="showHelper(true)" @blur="showHelper(false)"/>
        </div>
        <p :class="{ 'helper': helperVisible }">
            <span>&excl;</span> {{ helperText }}
        </p>
    </div>
</template>

<script>
export default {
    name : "InputBox",
    props: {
        inputId    : String,
        inputNm    : String,
        labelText  : String,
        maxLength  : String,
        iconSearch : Boolean,
        inputType  : String,
        inputValue : String,
        placeholder: String,
        disabled   : Boolean,
        readonly   : Boolean,
        important  : Boolean,
        helperText : String,
    },
    data() {
        return {
            helperVisible: false,
        }
    },
    computed: {
        inputClass() {
            return {
                'error-state'   : this.state === 'error',
                'disabled-state': this.state === 'disabled',
                'readonly-state': this.state === 'readOnly',
                'small'         : this.style === 'small',
                'regular'       : this.style === 'regular',
                'medium'        : this.style === 'medium',
            }
        }
    },
    methods : {
        handleInput(event) {
            this.$emit('input', event);
        },

        showHelper(visible) {
            this.helperVisible = visible;
        },
    }
}
</script>

<style lang="scss" scoped>
.input {
    display: block;
    position: relative;
    width: 100%;

    &.small {
        width: 140px;
    }

    &.regular {
        width: 200px;
    }

    &.semi-regular{
        width: 350px;
    }

    &.medium {
        width: 460px;
    }

    &.inline-block{
        display: inline-block;
    }

    label {
        position: absolute;
        font-size: $font11;
        font-weight: 400;
        color: $text-color-lv-3;
        z-index: 1;
        background-color: $bk-color-lv-3;
        left: 14px;
        top: -8px;

        span {
            color: $red-color-lv-2;
        }
    }

    &-wrapper {
        position: relative;
        width: 100%;

        img {
            @include my-auto;
            left: 10px;
            width: 20px;
            height: 20px;
        }

        input {
            border-radius: 4px;
            width: 100%;
            height: 38px;
            font-size: $font14;
            font-weight: 400;
            background-color: $bk-color-lv-3;
            border: 1px solid $line-color-lv-2;

            &:read-only {
                background-color: $table-color-lv-1;
            }
        }
    }

    p {
        display: none;

        span {
            display: none;
        }

        &.helper {
            color: $text-color-lv-5;
            font-size: $font12;
            font-weight: 400;
            display: block;
        }
    }

    &.error {
        input {
            border: 1px solid $red-color-lv-2;
        }

        p {
            span {
                display: inline;
            }

            &.helper {
                color: $red-color-lv-2;
            }
        }
    }
}
</style>