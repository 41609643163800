import { getDate, getConvertDate } from "@/assets/js/function";
const date = getDate('mon');
/**
 * @description 문의사항 모듈
 * @author 김원명, @date 2023-12-27
 */
const contactModule = {
    namespaced: true,
    state: {
        contact : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd'),
            },
            list : [],
            info : getContactInfo()
        }
    },
    mutations: {
        setList(state, newList) 
        {
            state.contact.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.contact.info = newInfo;
        },
        setReset(state)
        {
            state.contact.info = getContactInfo();
        }
    }
};

function getContactInfo()
{
    return {
        email       : '',
        gb          : '',
        contents    : '',
        tel         : '',
        answerNm    : '',
        answerDt    : '',
        history     : []
    }
}

export default contactModule;