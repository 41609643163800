import { getDate, getConvertDate } from "@/assets/js/function";
const date = getDate('mon');
/**
 * @description 이용약관 관리 모듈
 * @author 김원명, @date 2023-12-27
 */
const tosModule = {
    namespaced: true,
    state: {
        tos : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd'),
            },
            list : [],
            info : getTosInfo()
        }
    },
    mutations: {
        setList(state, newList) 
        {
            state.tos.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.tos.info = newInfo;
        },
        setReset(state)
        {
            state.tos.info = getTosInfo();
        }
    }
};

function getTosInfo()
{
    return {
        name   : '',
        regDt  : '',
        memo   : '',
        terms1 : '',
        terms2 : '',
        terms3 : '',
        terms4 : ''
    }
}

export default tosModule;