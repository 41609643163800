<template>
    <div class="inner box">
        <div class="box-title">
            <h2>이용약관</h2>
            <RoundLink linkText="이용약관 등록하기" href="/tosUpdate" />
        </div>
        <div class="margin grey-box">
            <SelectBox :labelText="'간편 조회'" :selectOptions="dateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>
        <BasicTable :columns="tableList" :rows="list" class="margin" @row-click="getDetail" />
    </div>
</template>

<script>
import SelectBox from "@/components/input/SelectBox.vue";
import RoundLink from "@/components/button/RoundLink.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import DateBox from "@/components/input/DateBox.vue";

import { mapState } from "vuex";
import { getTosList } from "@/assets/js/tos";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name: "TosList",
    components: {DateBox, BasicTable, RoundLink, SelectBox},
    computed: {
        ...mapState('data', {
            dateType  : state => state.data.dateType
        }),
        ...mapState('tos', {
            search  : state => state.tos.search,
            list    : state => state.tos.list
        })
    },
    created() {
        this.getList()
    },
    data() {
        return {
            tableList: [
                { key: "num", header: "번호", class: "w7" },
                { key: "code", header: "이력번호", class: "w9 blue" },
                { key: "name", header: "등록자", class: "w8" },
                { key: "regDt", header: "등록일시", class: "w12" },
                { key: "memo", header: "업데이트 사유", class: "" },
            ]
        }
    },
    methods : {
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            this.getList();
        },
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            this.getList();
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            this.getList();
        },
        getList () {
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            }

            getTosList(params);
        },
        getDetail(params) {
            this.$router.push({ path : `/tosUpdate/${params['ikey']}`});
        },
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}
</style>