<template>
    <div class="filter">
        <ul>
            <li v-for="(filter, index) in filters" :key="index" :class="{ 'active' : index === activeFilter }" @click="handleFilterClick(index)">
                {{ filter.title }} ({{ filter.count }})
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "FilterList",
    props: {
        filters: Array,
    },
    data() {
        return {
            activeFilter: null,
        }
    },
    methods: {
        handleFilterClick(index) {
            this.activeFilter = index;
            this.$emit('filter-changed', this.filters[index]);
        }
    },
    created() {
        if (this.filters.length > 0) {
            this.activeFilter = this.filters.length - 1;
        }
    }
}
</script>


<style lang="scss" scoped>
.filter{
    width: 100%;
    ul{
        display: flex;
        border-bottom: 1px solid $line-color-lv-4;

        li{
            font-size: $font18;
            font-weight: 700;
            cursor: pointer;
            padding: 18px 0;
            position: relative;

            &::after {
                display: block;
                content: '';
                width: 0;
                height: 6px;
                background-color: $blue-color-lv-4;
                left: 0;
                bottom: 0;
                position: absolute;
                transition: width .2s ease;
            }

            &:hover, &.active {
                &::after {
                    width: 100%;
                }
            }
        }

        li + li {
            margin-left: 76px;
        }
    }
}
</style>