<template>
    <div class="inner box">
        <div class="info-title">
            <h2>기관 정보</h2>
            <div v-if="info['state'] === 'E'">
                <BaseButton buttonText="승인 반려" class="grey" @click="getResultSet('N')"/>
                <BaseButton buttonText="승인" class="ml-10" @click="getResultSet('Y')"/>
            </div>
        </div>
        <LabelTable title="계정 정보" class="mt-10">
            <tr>
                <th>플랫폼 구분</th>
                <td>{{ info['platform'] }}</td>
            </tr>

            <tr>
                <th>가입 채널</th>
                <td>{{ info['channel'] }}</td>
            </tr>

            <tr>
                <th>가입일 / 최근 로그인</th>
                <td>{{ info['joinDt'] }} / {{ info['loginDt'] }}</td>
            </tr>

            <tr>
                <th>아이디</th>
                <td>{{ info['id'] }}</td>
            </tr>

            <tr>
                <th>휴대폰 인증 정보</th>
                <td>( {{ info['name'] }} ) {{ info['tel'] }}</td>
            </tr>

            <tr>
                <th>마케팅 정보 수신 동의</th>
                <td>{{ info['agree'] }}</td>
            </tr>

            <tr>
                <th>활동 / 정지 / 탈퇴 처리</th>
                <td class="p12">
                    <BaseButton buttonText="활동" />
                    <BaseButton buttonText="정지" class="grey" />
                    <!-- <span class="red mr-10">활동 정지 23.01.01 ~ 23.01.10 / 등록자: admin2 / 정지사유: 회원님의 서비스 허위 정보가 확인되어 60일간 거래가 정지되었습니다. 궁금한 사항이 있으실 경우 채팅 고객센터를 통해 연락 부탁드립니다.</span>
                    <BaseButton buttonText="정지해제" class="grey" /> -->
                </td>
            </tr>
        </LabelTable>

        <LabelTable title="인증 기관 정보" class="mt- 10">
            <tr>
                <th>로고 또는 사진</th>
                <td>
                    <RepImage noneTitle :imgTitle="'로고사진'" :imageView="info['img1']" />
                </td>
            </tr>

            <tr>
                <th>전문성 어필 이미지</th>
                <td>
                    <RepImage :imgTitle="'대표사진'" :imageView="info['img2']"/>
                    <RepImage :imgTitle="'2'" :imageView="info['img3']"/>
                    <RepImage :imgTitle="'3'" :imageView="info['img4']"/>
                    <RepImage :imgTitle="'4'" :imageView="info['img5']"/>
                </td>
            </tr>

            <tr>
                <th>기관코드</th>
                <td>{{ oaCd }}</td>
            </tr>

            <tr>
                <th>국가</th>
                <td>대한민국</td>
            </tr>

            <tr>
                <th>기관명</th>
                <td>{{ info['ogNm'] }}</td>
            </tr>

            <tr>
                <th>주소</th>
                <td>{{ info['addr'] }}</td>
            </tr>

            <tr>
                <th>담당자 이름</th>
                <td>{{ info['charPerNm'] }}</td>
            </tr>

            <tr>
                <th>대표전화</th>
                <td>{{ info['tel'] }}</td>
            </tr>

            <tr>
                <th>담당자 휴대폰</th>
                <td>{{ info['charPerTel'] }}</td>
            </tr>

            <tr>
                <th>FAX</th>
                <td>{{ info['fax'] }}</td>
            </tr>

            <tr>
                <th>기관 소개</th>
                <td>{{ info['memo'] }}</td>
            </tr>

            <tr>
                <th>은행</th>
                <td>{{ info['bankNm'] }}</td>
            </tr>

            <tr>
                <th>예금주명</th>
                <td>{{ info['accNm'] }}</td>
            </tr>

            <tr>
                <th>계좌번호</th>
                <td>{{ info['account'] }}</td>
            </tr>

            <!-- <tr>
                <th>매장 설명</th>
                <td>매장 설명 내용</td>
            </tr> -->

            <tr>
                <th>사업자등록증</th>
                <td class="p12">
                    <p>사업자명: {{ info['einNm'] }}</p>
                    <p>사업자 등록번호: {{ info['einNum'] }}</p>
                    <p>대표자: {{ info['ceoNm'] }}</p>
                    <p>업태: {{ info['einType'] }}</p>
                    <p>종목: {{ info['einItem'] }}</p>
                    <p>세금계산서 이메일: {{ info['einEmail'] }}</p>
                </td>
            </tr>

            <tr>
                <th>첨부파일</th>
                <td class="p12">
                    <ul>
                        <li class="orange" v-if="info['img6']">
                            <a :href="`${downloadPath}/${info['img6']}`" download>사업자등록증</a>
                        </li>
                        <li class="orange" v-for="(item, index) in info['fileOther']" :key="index">
                            <a :href="`${downloadPath}/${item['file']}`" download>파일{{ index + 1 }}</a>
                        </li>
                    </ul>
                </td>
            </tr>
        </LabelTable>

        <LabelTable title="활동 현황" class="mt-10">
            <tr>
                <th>누적 공고등록</th>
                <td>{{ info['lcCnt'] }}건</td>
            </tr>

            <tr>
                <th>누적 매칭성공</th>
                <td>{{ info['matchCnt'] }}건</td>
            </tr>

            <tr>
                <th>누적 강의완료</th>
                <td>
                    {{ info['compCnt'] }}건 / {{ info['compAmt'] }}원
                    <BaseButton buttonText="거래원장" class="mx-right" />
                </td>
            </tr>

            <tr>
                <th>누적 강의취소</th>
                <td>{{ info['cancleCnt'] }}건</td>
            </tr>

            <tr>
                <th>관리자 메모</th>
                <td class="p12">
                    <TextareaBox placeholder="메모사항" class="w80" :inputValue="inputMemo" @input="getInputMemo"/>
                    <BaseButton buttonText="메모 저장" class="mx-right" @click="getResultSet('M')"/>
                </td>
            </tr>

            <tr>
                <th>메모 변경 히스토리</th>
                <td>
                    <p v-for="(item, index) in info['userMemo']" :key="index">
                        {{ item.name }} / {{ item.regDt }} / 메모: {{ item.memo }}
                    </p>
                </td>
            </tr>
        </LabelTable>
    </div>
    <WhiteAlert title="정지" buttonText="확인" class="red" v-if="showPopup">
        <p class="mb-25">정지 처리를 진행하시겠습니까?</p>
    </WhiteAlert>

    <WhiteAlert title="정지해제" buttonText="확인" class="red" v-if="showPopup">
        <p class="mb-25">정지 처리를 진행하시겠습니까?</p>
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import RepImage from "@/components/input/RepImage.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getAgencyMemMngInfo } from "@/assets/js/agencyMemMng";
import { getAxiosData } from "@/assets/js/function";

export default {
    name: "AgencyInfo",
    components: {WhiteAlert, TextareaBox, RepImage, BaseButton, LabelTable, Confirm},
    computed: {
        ...mapState('agencyMemMng', {
            info    : state => state.agencyMemMng.info
        })
    },
    created() {
        this.getInfo();
    },
    data() {
        return{
            state               : 'N',
            resultYn            : 'N',
            compYn              : 'N',
            oaCd                : this.$route.params.oaCd,
            downloadPath        : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            inputMemo           : ''
        }
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getResult();
                }

                if(this.state === 'M')
                {
                    this.getMemoResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                if(this.state === 'M')
                {
                    this.getInfo();
                }
                else
                {
                    this.$router.go(-1);
                }
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getInfo() {
            const params = {
                oaCd : this.$route.params.oaCd
            };
            
            getAgencyMemMngInfo(params);
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInputMemo() {
            this.inputMemo = event.target.value;
        },
        getResultSet(gb) {
            this.state = gb;

            switch(gb)
            {
                case 'Y':
                    this.getConfirmSet('confirm', '승인', '승인 처리하시겠습니까?');
                break;
                case 'N':
                    this.getConfirmSet('confirm', '반려', '반려 처리하시겠습니까?');
                break;
                case 'M':
                    this.getConfirmSet('confirm', '저장', '메모를 저장하시겠습니까?');
                break;
            }

            if(gb !== 'M')
            {
                this.resultYn = 'Y';
            }
        },
        async getResult() {
            this.resultYn = 'N';

            try
            {
                const params = {
                    oaCd   : this.oaCd,
                    ogCd   : this.info['ogCd'],
                    state  : this.state,
                    lastDt : this.info['lastDt']
                };

                const res = await getAxiosData('https://data.wecls.com/admin/organList/stateChange', params);

                console.log(res);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '확인', '처리되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        },
        async getMemoResult() {
            try
            {
                const params = {
                    oaCd : this.oaCd,
                    memo : this.inputMemo
                };

                const res = await getAxiosData('https://data.wecls.com/admin/organList/memoProc', params);

                if(res['status'] === 200)
                {
                    this.inputMemo = '';
                    this.compYn    = 'Y';
                    this.getConfirmSet('alert', '확인', '메모가 저장되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                this.inputMemo = '';
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.info-title{
    width: 100%;
    position: relative;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.20);
    padding: 20px;

    h2{
        font-size: $font24;
        font-weight: 700;
    }

    div{
        @include my-auto;
        right: 20px;
    }
}

table{
    tr{
        td{
            p{
                color: $text-color-lv-0;
            }
            ul{
                li{
                    list-style: disc;
                    margin-left: 18px;
                }
            }

            .mx-right{
                right: 12px;
            }

            .w80{
                width: 998px;
            }
        }
    }
}

.alert{
    p{
        font-size: $font12;
    }
}
</style>