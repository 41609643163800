/**
 * @description 로그인 vuex 모듈 관련
 * @author 김원명, @date 2023-12-22
 */
const loginModule = {
    namespaced  : true,
    state       : {
        login : 'N'
    },
    mutations   : {
        setLogin(state, gb)
        {
            state.login = !gb ? 'N' : gb;
        }
    },
    actions : {
        login({ commit })
        {
            commit('setLogin', 'Y');
            localStorage.setItem('login', 'Y');
        },
        logout({ commit })
        {
            commit('setLogin', 'N');
            localStorage.setItem('login', 'N');
        },
        initializeState({ commit }) 
        {
            const login  = localStorage.getItem('login');
            commit('setLogin', login);
        }
    }
};
  
export default loginModule;