<template>
    <div class="inner box">
        <div class="box-title">
            <h2>공지사항</h2>
            <RoundLink linkText="공지사항 등록하기" href="/noticeUpdate" />
        </div>
        <div class="margin grey-box">
            <SelectBox :labelText="'간편 조회'" :selectOptions="dateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />
            <SelectBox labelText="상태별 조회" :selectOptions="notice" :selectId="'notice'" :selectVal="search['notice']" :selectChange="getSelectSet" class="small"/>

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>
        <BasicTable :columns="tableList" :rows="list" class="margin" @row-click="getDetail"/>
    </div>
</template>

<script>
import SelectBox from "@/components/input/SelectBox.vue";
import DateBox from "@/components/input/DateBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import RoundLink from "@/components/button/RoundLink.vue";

import { getNoticeList } from "@/assets/js/notice";
import { mapState } from "vuex";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name      : "NoticeList",
    components: {RoundLink, BasicTable, DateBox, SelectBox},
    computed: {
        ...mapState('data', {
            dateType : state => state.data.dateType,
            notice   : state => state.data.notice
        }),
        ...mapState('notice',{
            search  : state => state.notice.search,
            list    : state => state.notice.list
        })
    },
    data() {
        return {
            tableList: [
                {key: "num", header : "번호", class: "w7"},
                {key: "title", header : "제목", class: "blue"},
                {key: "gb", header : "구분", class: "w10"},
                {key: "writer", header : "작성자", class: "w8"},
                {key: "regDt", header : "작성일시", class: "w12"},
                {key: "viewCnt", header : "조회수", class: "w8"},
            ]
        }
    },
    created () {
        this.getList();
    },  
    methods : {
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            this.getList();
        },
        
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            this.getList();
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            this.getList();
        },
        getDetail(params){
            this.$router.push({ path: `/noticeUpdate/${params['ikey']}` });
        },
        getList(){
            const params = {
                gb      : this.search['notice']['value'],
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getNoticeList(params);
        }
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}
</style>