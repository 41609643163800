import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 기관 회원관리
 * @author 김원명, @date 2023-12-22
 */
const agencyMemMngModule = {
    namespaced  : true,
    state       : {
        agencyMemMng : {
            headers : [
                { title : '이번달 신규가입', count : 0 },
                { title : '이번달 탈퇴', count : 0 },
                { title : '기관정보 가입승인 대기', count : 0 },
                { title : '기관정보 업데이트 대기', count : 0 },
                { title : '기관정보 업데이트 승인', count : 0 }
            ],
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd'),
                expert  : { value : '', name : '전체' },
                channel : { value : '', name : '전체' }
            },
            filters : [
                { title : '일반회원', count : 0 },
                { title : '정지회원', count : 0 },
                { title : '탈퇴회원', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : [],
            info    : getAgencyMemMngInfo()
        }
    },
    mutations: {
        setHeaders(state, newHeaders)
        {
            state.agencyMemMng.headers = newHeaders;
        },
        setFilters(state, newFilters)
        {
            state.agencyMemMng.filters = newFilters;
        },
        setAllList(state, newAllList)
        {
            state.agencyMemMng.allList = newAllList;
            state.agencyMemMng.list    = newAllList;
        },
        setList(state, newList)
        {
            state.agencyMemMng.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.agencyMemMng.info = newInfo;
        }
    }
};

function getAgencyMemMngInfo()
{
    return {
        platform    : '기관',
        channel     : '',
        joinDt      : '',
        loginDt     : '',
        id          : '',
        name        : '',
        tel         : '',
        agree       : '',
        img1        : '', /** 로고 사진 */
        img2        : '', /** 전문성 이미지1 */
        img3        : '', /** 전문성 이미지2 */
        img4        : '', /** 전문성 이미지3 */
        img5        : '', /** 전문성 이미지4 */
        cntry       : '',
        ogNm        : '',
        addr        : '',
        charPerNm   : '',
        charPerTel  : '',
        fax         : '',
        memo        : '',
        bankNm      : '',
        accNm       : '',
        account     : '',
        einNm       : '',
        einNum      : '',
        ceoNm       : '',
        einType     : '',
        einItem     : '',
        einEmail    : '',
        img6        : '', /** 사업자이미지 */
        fileOther   : [],
        lcCnt       : '',
        matchCnt    : '',
        compCnt     : '',
        compAmt     : '',
        cancleCnt   : '',
        userMemo    : [],
        state       : '',
        lastDt      : ''
    }
}

export default agencyMemMngModule;
