import { getAxiosData } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description 공지사항 리스트
 */
export async function getNoticeList(params)
{
    console.log(params);
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/notice/getList', params)
        const list = res.data.map((item, index) => {
            return {
                ikey        : item.ikey,
                num         : index+1,
                title       : item.title,
                gb          : item.gb === 'U' ? '업데이트' : (item.gb === 'N' ? '일반 공지' : '긴급 공지'),
                contents    : item.contents,
                useYn       : item.useYn,
                regDt       : item.regDt,
                writer      : item.writer,
                viewCnt     : item.viewCnt,
            }
        });

        store.commit('notice/setList', list);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 공지사항 정보
 */
export async function getNoticeInfo(params) 
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/notice/getInfo', params);
        console.log(res);

        const info = {
            notice   : { value : res.data['gb'], name : res.data['gb'] === 'U' ? '업데이트' : ( res.data['gb'] === 'N' ? '일반 공지' : '긴급 공지') },
            title    : res.data['title'],
            contents : res.data['contents']
        };

        console.log(info);

        store.commit('notice/setInfo', info);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}