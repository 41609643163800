/**
 * @description 입력 필요 data
 * @author 김원명, @date 2023-12-22
 */
const dataModule = {
    namespaced: true,
    state: {
        data : {
            /** 기간 구분 */
            dateType  : [
                { value : 'mon', name : '이달' },
                { value : 'day', name : '당일' },
                { value : 'mon1', name : '1개월' },
                { value : 'mon2', name : '2개월' },
                { value : 'mon3', name : '3개월' },
                { value : 'year1', name : '1년' }
            ],
            /** 전문가 등록 조회 구분 */
            expert : [
                { value : '', name : '전체' },
                // { value : '', name : '미신청' },
                { value : 'E', name : '신청' },
                { value : 'Y', name : '승인완료' },
                { value : 'N', name : '승인불가' }
            ],
            /** 가입 채널 구분 */
            channel : [
                { value : '', name : '전체' },
                { value : 'D', name : '일반' },
                { value : 'K', name : '카카오' },
                { value : 'N', name : '네이버' }
            ],
            /** 공지사항 상태 */
            notice  : [
                { value : '', name : '전체' },
                { value : 'U', name : '업데이트' },
                { value : 'N', name : '일반 공지' },
                { value : 'E', name : '긴급 공지' },
            ],
            /** 알람 회원 구분 */
            alarm    : [
                { value : 'A', name : '전체' },
                { value : 'I', name : '강사' },
                { value : 'O', name : '기관' }
            ],
            /** 입금 조회 구분 */
            dep      : [
                { value : '', name : '전체' },
                { value : 'E', name : '입금대기' },
                { value : 'Y', name : '입금완료' }
            ],
            /** 지급 조회 구분 */
            pay      : [
                { value : '', name : '전체' },
                { value : 'E', name : '지급대기' },
                { value : 'Y', name : '지급완료' }
            ],
            /** 강의 분야(메인) */
            mainLecture : [
                {value: '011', name: '심리/상담'},
                {value: '012', name: '요리'},
                {value: '013', name: '연기'},
                {value: '014', name: '생활/취미'},
                {value: '015', name: '컴퓨터/IT'},
                {value: '016', name: '강의/강연'},
                {value: '017', name: '학습/지도'},
                {value: '018', name: '음악'},
                {value: '019', name: '댄스'},
                {value: '020', name: '운동/레저'},
                {value: '021', name: '미술'},
                {value: '022', name: '공예'}
            ],
            /** 강의 분야 */
            lecture : [
                {key : '011', value: '001', name: '미술치료'},
                {key : '011', value: '002', name: '음악치료'},
                {key : '011', value: '003', name: '놀이치료'},
                {key : '011', value: '004', name: '상담'},
                {key : '011', value: '005', name: '연극치료'},
                {key : '011', value: '006', name: '언어치료'},
                {key : '011', value: '007', name: '심리검사'},
                {key : '011', value: '008', name: '집단치료/상담'},
                {key : '011', value: '009', name: '스누젤렌'},
                {key : '011', value: '010', name: '보이타'},
                {key : '011', value: '011', name: '기타 심리/상담'},
                {key : '012', value: '012', name: '한식'},
                {key : '012', value: '013', name: '일식'},
                {key : '012', value: '014', name: '중식'},
                {key : '012', value: '015', name: '아시아요리'},
                {key : '012', value: '016', name: '양식'},
                {key : '012', value: '017', name: '펫푸드'},
                {key : '012', value: '018', name: '키즈쿠킹'},
                {key : '012', value: '019', name: '커피/음료/차'},
                {key : '012', value: '020', name: '베이킹'},
                {key : '012', value: '021', name: '술/전통주'},
                {key : '012', value: '022', name: '자격증취득'},
                {key : '012', value: '023', name: '기타'},
                {key : '013', value: '024', name: '방송연기'},
                {key : '013', value: '025', name: '연극'},
                {key : '013', value: '026', name: '성우'},
                {key : '013', value: '027', name: '뮤지컬'},
                {key : '013', value: '028', name: '인형극'},
                {key : '013', value: '029', name: '동화구연'},
                {key : '014', value: '030', name: '응급/구조'},
                {key : '014', value: '031', name: '마술'},
                {key : '014', value: '032', name: '드론'},
                {key : '014', value: '033', name: '꽃꽂이'},
                {key : '014', value: '034', name: '글쓰기/독서'},
                {key : '014', value: '035', name: '정리/수납'},
                {key : '014', value: '036', name: '경제/재테크'},
                {key : '014', value: '037', name: '체험'},
                {key : '014', value: '038', name: '사진'},
                {key : '014', value: '039', name: '다도'},
                {key : '014', value: '040', name: '보드게임'},
                {key : '014', value: '041', name: '역학'},
                {key : '014', value: '042', name: '기타'},
                {key : '015', value: '043', name: '영상편집'},
                {key : '015', value: '044', name: '포토샵/일러스트'},
                {key : '015', value: '045', name: '코딩'},
                {key : '015', value: '046', name: '게임'},
                {key : '015', value: '047', name: '컴퓨터 실용(한글,엑셀,PPT)'},
                {key : '015', value: '048', name: '컴퓨터 기초'},
                {key : '015', value: '049', name: '스마트폰 기초'},
                {key : '015', value: '050', name: 'SNS'},
                {key : '015', value: '051', name: '자격증취득'},
                {key : '015', value: '052', name: '기타 컴퓨터/IT'},
                {key : '016', value: '053', name: '워크샵'},
                {key : '016', value: '054', name: '기업연수'},
                {key : '016', value: '055', name: '면접컨설팅'},
                {key : '016', value: '056', name: '직업훈련'},
                {key : '016', value: '057', name: '인적성'},
                {key : '016', value: '058', name: '기타 강의/강연'},
                {key : '017', value: '059', name: '수어/점자'},
                {key : '017', value: '060', name: '한글기초'},
                {key : '017', value: '061', name: '검정고시'},
                {key : '017', value: '062', name: '전문자격증'},
                {key : '017', value: '063', name: '외국어'},
                {key : '017', value: '064', name: '회화'},
                {key : '017', value: '065', name: '교과목'},
                {key : '017', value: '066', name: '역사'},
                {key : '017', value: '067', name: '토론/스피치'},
                {key : '017', value: '068', name: '기타 학습/지도'},
                {key : '018', value: '069', name: '실용음악'},
                {key : '018', value: '070', name: '트로트'},
                {key : '018', value: '071', name: '악기연주'},
                {key : '018', value: '072', name: '합창'},
                {key : '018', value: '073', name: '국악'},
                {key : '018', value: '074', name: '전통음악'},
                {key : '018', value: '075', name: '비트박스'},
                {key : '018', value: '076', name: '성악'},
                {key : '018', value: '077', name: '작사/작곡'},
                {key : '018', value: '078', name: '랩'},
                {key : '018', value: '079', name: '기타 음악'},
                {key : '019', value: '080', name: '민속무용'},
                {key : '019', value: '081', name: '현대무용'},
                {key : '019', value: '082', name: '발레'},
                {key : '019', value: '083', name: '방송댄스'},
                {key : '019', value: '084', name: '비보이'},
                {key : '019', value: '085', name: '기타 댄스'},
                {key : '020', value: '086', name: '유아체육'},
                {key : '020', value: '087', name: '재활체육'},
                {key : '020', value: '088', name: '생활체육'},
                {key : '020', value: '089', name: '요가/필라테스'},
                {key : '020', value: '090', name: '수영'},
                {key : '020', value: '091', name: '헬스'},
                {key : '020', value: '092', name: '구기종목'},
                {key : '020', value: '093', name: '마라톤'},
                {key : '020', value: '094', name: '자전거'},
                {key : '020', value: '095', name: '실버체조'},
                {key : '020', value: '096', name: '기타 운동/레저'},
                {key : '021', value: '097', name: '동양화'},
                {key : '021', value: '098', name: '서양화'},
                {key : '021', value: '099', name: '캘리그래피'},
                {key : '021', value: '100', name: '드로잉'},
                {key : '021', value: '101', name: '만화/애니/웹툰'},
                {key : '021', value: '102', name: '아동미술'},
                {key : '021', value: '103', name: '퍼포먼스미술'},
                {key : '021', value: '104', name: '디자인'},
                {key : '021', value: '105', name: '서예'},
                {key : '021', value: '106', name: '기타 미술'},
                {key : '022', value: '107', name: '종이공예'},
                {key : '022', value: '108', name: '도자공예'},
                {key : '022', value: '109', name: '목공예'},
                {key : '022', value: '110', name: '유리공예'},
                {key : '022', value: '111', name: '가죽공예'},
                {key : '022', value: '112', name: '비즈공예'},
                {key : '022', value: '113', name: '라탄공예'},
                {key : '022', value: '114', name: '섬유공예'},
                {key : '022', value: '115', name: '기타 공예'},
            ],
        }
    }
};

export default dataModule;