<template>
    <div class="join intro">
        <MobileBox title="약관동의" showPrev>
            <div class="join-container">
                <div>
                    <h2>
                        <span>위클즈</span>
                        약관에 동의해주세요.
                    </h2>

                    <p>모든 이용자는 위클즈의 이용약관 및 개인정보 처리 방침에 동의해야합니다.</p>
                </div>

                <CheckBox labelText="모두 동의합니다." inputId="all" inputNm="agree" :checkStat="''" @change="''"/>

                <span class="border" />

                <CheckBox labelText="[필수] 만 14세 이상입니다." inputId="age" inputNm="agree" :checkStat="''" showDetail @change="''"/>
                <CheckBox labelText="[필수] 이용약관 동의" inputId="use" inputNm="agree" :checkStat="''" showDetail @change="''"/>
                <CheckBox labelText="[필수] 개인정보 수집 - 이용 동의" inputId="info" :checkStat="''" inputNm="agree" showDetail @change="''"/>
                <CheckBox labelText="[선택사항] 마케팅 정보 수신 동의" inputId="choice" :checkStat="''" inputNm="agree" showDetail @change="''"/>
                <div class="bottom">
                    <BaseButton buttonText="동의하고 계속하기" class="w-full" @click="''" />
                </div>
            </div>
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="''" @getClose="''"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import CheckBox from "@/components/input/CheckBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

export default {
    name: "JoinAgree",
    components: {BaseButton, CheckBox, MobileBox, Confirm},
    data () {
        return {
            btnClass            : 'grey',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : ''
        }
    },
}
</script>

<style lang="scss" scoped>
.join {
    &-container {
        padding: 13px;

        h2 {
            font-size: $font20;
            font-weight: 700;
            color: $text-color-lv-2;
            margin-bottom: 15px;

            span {
                display: block;
                color: $blue-color-lv-4;
            }

            & + p {
                font-size: $font10;
                font-weight: 700;
                color: $line-color-lv-1;
                margin-bottom: 27px;
            }
        }

        .border {
            display: block;
            width: 100%;
            height: 1px;
            background-color: $line-color-lv-1;
            margin: 4px 0;
        }

        .bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 8px 16px 14px 16px;
            @include shadow2;
            width: 100%;
            background-color: $bk-color-lv-3;
            z-index: 2;
        }
    }
}
</style>