<template>
    <div class="inner box">
        <div class="box-title">
            <h2>문의하기</h2>
        </div>
        <div class="margin grey-box">
            <SelectBox :labelText="'간편 조회'" :selectOptions="dateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>

        <BasicTable :columns="tableList" :rows="list" class="margin" @row-click="getDetail"/>
    </div>
</template>

<script>
import SelectBox from "@/components/input/SelectBox.vue";
import DateBox from "@/components/input/DateBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";

import { getContactList } from "@/assets/js/contact";
import { mapState } from "vuex";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name: "ContactList",
    components: {BasicTable, DateBox, SelectBox},
    computed: {
        ...mapState('data', {
            dateType : state => state.data.dateType
        }),
        ...mapState('contact',{
            search  : state => state.contact.search,
            list    : state => state.contact.list
        })
    },
    created () {
        this.getList();
    },
    data() {
        return {
            tableList: [
                {key: "num", header: "번호", class: "w6"},
                {key: "contents", header: "문의 내용", class: "blue"},
                // {key: "writer", header: "작성자", class: "w7"},
                {key: "regDt", header: "작성일시", class: "w12"},
                {key: "answerNm", header: "답변 담당자", class: "w7"},
                {key: "answerDt", header: "답변일시", class: "w12"},
                {key: "answerYn", header: "답변유무", class: "w7"},
            ]
        }
    },
    methods : {
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            this.getList();
        },
        
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            this.getList();
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            this.getList();
        },
        getDetail(params){
            this.$router.push({ path: `/contactMng/${params['ikey']}` });
        },
        getList(){
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getContactList(params);
        }
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}
</style>