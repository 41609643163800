<template>
    <div class="alert">
        <div class="alert-wrapper">
            <h3>
                {{ title }}
            </h3>

            <slot></slot>

            <div class="button-wrapper">
                <BaseButton buttonText="취소" class="grey p10 regular" @click="getClose" />
                <BaseButton :buttonText="buttonText" class="p10 regular" @click="getResult"/>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "@/components/button/BaseButton.vue";

export default {
    name      : "WhiteAlert",
    components: {BaseButton},
    props     : {
        title     : String,
        buttonText: String,
    },
    data () {

    },

    methods : {
        getClose() {
            this.$emit("close-popup");
        },
        getResult(){
            this.$emit("confirm-popup");
        }
    }

}

</script>

<style lang="scss" scoped>
.alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    align-items: center;

    &.blue{
        h3{
            color: $blue-color-lv-4;
        }
    }

    &.red{
        h3{
            color: $red-color-lv-2;
        }
    }

    &-wrapper {
        border-radius: 4px;
        background-color: $bk-color-lv-3;
        @include shadow16;
        width: 370px;
        padding: 32px 26px;
        text-align: center;
        margin: 0 auto;

        h3 {
            color: $text-color-lv-0;
            font-size: $font16;
            font-weight: 500;
            margin-bottom: 14px;
        }

        .button-wrapper {
            button + button {
                margin-left: 10px;
            }
        }
    }
}
</style>