<template>
    <a class="button" @click="getMove">{{ linkText }}</a>
</template>

<script>
export default {
    name: "RoundLink",
    props: {
        href: String,
        linkText: String,
    },
    methods : {
        getMove() {
            this.$router.push({ path : `${this.href}`})
        }
    }
}
</script>

<style lang="scss" scoped>
.button{
    padding: 16px 26px;
    border-radius: 30px;
    background-color: $blue-color-lv-4;
    color: $bk-color-lv-3;
    font-size: $font16;
    font-weight: 700;
}
</style>