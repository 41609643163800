<template>
    <div class="login intro">
        <MobileBox title="로그인">
                <InputBox :style="'outline'" class="mb-24" labelText="이메일" :inputId="'id'" :inputValue="id" placeholder="이메일을 입력해주세요." @keyup.enter="getEnter" @input="getInputSet"/>                
                <InputBox :style="'outline'" class="mb-16" labelText="비밀번호" :inputId="'pw'" :inputValue="pw" placeholder="비밀번호를 입력해주세요" inputType="password" @keyup.enter="getEnter" @input="getInputSet"/>
            <BaseButton buttonText="이메일 로그인" class="grey w-full" @click="getLogin"/>
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmResult"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import InputBox from "@/components/input/InputBox.vue";
// import CheckBox from "@/components/input/CheckBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";
import { getAxiosData } from "@/assets/js/function"
// import axios from "axios";

export default {
    name: "MainLogin",
    components: {BaseButton, InputBox, MobileBox, Confirm},
    data() {
        return {
            id                  : '',
            pw                  : '',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
        }
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInputSet() {
            const id  = event.target.id;
            const val = event.target.value;

            if(id === 'id')
            {
                this.id = val;
            }
            else
            {
                this.pw = val;
            }
        },
        getEnter() {
            const id  = event.target.id;

            if(id === 'id')
            {
                const pw = document.getElementById("pw");

                pw.focus();
            }
            else
            {
                this.getLogin();
            }
        },
        async getLogin() {
            try 
            {
                const params = {
                    id  : this.id,
                    pw  : this.pw
                };

                const res  = await getAxiosData('https://data.wecls.com/admin/login/loginCheck', params);

                if(res['status'] === 200)
                {
                    this.$store.dispatch('login/login', 'Y');
                    this.$router.push({path : `/agencyMemMng`});
                }
                else
                {
                    this.getConfirmSet('alert', '실패', '로그인에 실패하였습니다. 이메일과 비밀번호를 확인해주세요.');
                }
            }
            catch (error)
            {
                console.log(error);
                this.getConfirmSet('alert', '실패', '로그인에 실패하였습니다. 이메일과 비밀번호를 확인해주세요.');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    button {
        margin: 10px 0 24px 0;
    }

    ul {
        height: 32px;
        align-items: center;
        flex-direction: row-reverse;

        li {
            font-size: $font12;
            font-weight: 500;
            color: $text-color-lv-5;
            margin-left: 10px;
            cursor: pointer;
            height: $font12;
            line-height: $font12;

            &:first-child {
                border-left: 1px solid $text-color-lv-5;
                padding-left: 10px;
            }
        }
    }

    p {
        text-align: center;
        font-size: $font14;
        color: $text-color-lv-5;
        font-weight: 500;

        span {
            color: $blue-color-lv-4;
            font-weight: 700;
            cursor: pointer;
        }
    }
}
</style>