<template>
    <div class="editor">
        <div v-if="editor" class="editor-button">
            <button @click="editor.chain().focus().toggleBold().run()"
                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold') }">
                <FontAwesomeIcon icon="bold"/>
            </button>
            <button @click="editor.chain().focus().toggleItalic().run()"
                    :disabled="!editor.can().chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic') }">
                <FontAwesomeIcon icon="italic" />
            </button>
            <button @click="editor.chain().focus().toggleStrike().run()"
                    :disabled="!editor.can().chain().focus().toggleStrike().run()"
                    :class="{ 'is-active': editor.isActive('strike') }">
                <FontAwesomeIcon icon="strikethrough" />
            </button>
            <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
                <FontAwesomeIcon icon="underline" />
            </button>
            <button @click="editor.chain().focus().unsetAllMarks().run()">
                <FontAwesomeIcon icon="text-slash" />
            </button>

            <span class="border"></span>

            <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
                <FontAwesomeIcon icon="align-left" />
            </button>
            <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
                <FontAwesomeIcon icon="align-center" />
            </button>
            <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
                <FontAwesomeIcon icon="align-right" />
            </button>
            <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
                <FontAwesomeIcon icon="align-justify" />
            </button>

            <span class="border"></span>

            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                <FontAwesomeIcon icon="heading" />
            </button>
            <button @click="editor.chain().focus().setParagraph().run()"
                    :class="{ 'is-active': editor.isActive('paragraph') }">
                <FontAwesomeIcon icon="p" />
            </button>

            <span class="border"></span>

            <button @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editor.isActive('bulletList') }">
                <FontAwesomeIcon icon="list-ul" />
            </button>
            <button @click="editor.chain().focus().toggleOrderedList().run( )"
                    :class="{ 'is-active': editor.isActive('orderedList') }">
                <FontAwesomeIcon icon="list-ol" />
            </button>

            <span class="border"></span>

            <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                <FontAwesomeIcon icon="link" />
            </button>
            <button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                <FontAwesomeIcon icon="link-slash" />
            </button>

            <input type="file" ref="fileInput" @change="uploadImage" style="display: none" />
            <button @click="openFileInput">Upload Image</button>

            <span class="border"></span>

            <button>
                <input
                        type="color"
                        @input="editor.chain().focus().setColor($event.target.value).run()"
                        :value="editor.getAttributes('textStyle').color"
                >
            </button>
            <button @click="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">
                <FontAwesomeIcon icon="highlighter" />
            </button>
            <button @click="editor.chain().focus().setHorizontalRule().run()">
                <FontAwesomeIcon icon="ruler-horizontal" />
            </button>

            <span class="border"></span>

            <button @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
                <FontAwesomeIcon icon="rotate-left" />
            </button>
            <button @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
                <FontAwesomeIcon icon="rotate-right" />
            </button>
        </div>
        <editor-content :editor="editor" class="editor-content" />
    </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'

export default {
    components: {
        FontAwesomeIcon,
        EditorContent,
    },
    props: {
        modelValue: String,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const editor = ref(null);

        onMounted(() => {
            editor.value = new Editor({
                extensions: [
                    StarterKit,
                    Underline,
                    TextStyle,
                    Color,
                    Highlight,
                    Link.configure({
                        openOnClick: false,
                    }),
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                ],
                content: props.modelValue,
                onUpdate: ({ editor }) => {
                    const newValue = editor.getHTML();
                    if (props.modelValue !== newValue) {
                        emit('update:modelValue', newValue);
                    }
                },
            });
        });

        watch(() => props.modelValue, (newValue) => {
            if (editor.value && newValue !== editor.value.getHTML()) {
                editor.value.commands.setContent(newValue);
            }
        });


        onUnmounted(() => {
            editor.value?.destroy();
        });

        return {
            editor,
        };
    },
    methods: {
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
    },

    beforeUnmount() {
        this.editor.destroy()
    },
};
</script>

<style lang="scss">
.border{
    width: 1px;
    height: 20px;
    background-color: $line-color-lv-1;
    margin: 5px 9px 0px 9px;
}

.editor {
    border: 1px solid $line-color-lv-4;

    &-button {
        background-color: $bk-color-lv-1;
        padding: 5px 5px;
        display: flex;
        vertical-align: middle;

        button {
            padding: 5px 5px;
            border-radius: 2px;

            &.is-active {
                background-color: $line-color-lv-2;
            }

            input[type=color]{
                width: 14px;
                height: 14px;
                background-color: inherit;
                border: 0;
                padding: 0;
                margin-top: 3px;

                &::-webkit-color-swatch-wrapper{
                    padding: 0;
                    margin: 0;
                }

                &::-webkit-color-swatch{
                    border: 0;
                    padding: 0;
                    margin: 0;
                }
            }
        }

        button+button {
            margin-left: 10px;
        }
    }

    &-content {
        height: 500px;
        overflow-y: auto;
        padding: 20px;

        .tiptap {
            >*+* {
                margin-top: 0.75em;
            }

            a {
                color: #1155cc;
                text-decoration: underline;
            }

            u{
                text-decoration: underline;
            }
        }

        .content {
            padding: 1rem 0 0;

            h3 {
                margin: 1rem 0 0.5rem;
            }

            pre {
                border-radius: 5px;
                color: #333;
            }

            code {
                display: block;
                white-space: pre-wrap;
                font-size: 0.8rem;
                padding: 0.75rem 1rem;
                background-color: #e9ecef;
                color: #495057;
            }
        }

        ul {
            margin-left: 20px;

            li {
                list-style: disc;
            }
        }

        ol {
            margin-left: 20px;

            li {
                list-style: decimal;
            }
        }
    }
}</style>