<template>
    <div class="container" :class="{ 'w-full': showLoginClass }">
        <SideHeader v-if="showHeader" />
        <div class="section">
            <TopHeader v-if="showHeader" />
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

import TopHeader from "@/views/TopHeader.vue";
import SideHeader from "@/views/SideHeader.vue";

export default {
    name: 'App',
    components: {SideHeader, TopHeader},
    computed: {
        showHeader() {
            const currentPath = this.$route.path
            return !currentPath.startsWith('/login') && !currentPath.startsWith('/join')
        },
        showLoginClass() {
            const currentPath = this.$route.path;
            return currentPath.startsWith('/login') || currentPath.startsWith('/join');
        }
    }
}
</script>

<style lang="scss">
.container{
    width: 100%;
    position: relative;
    background: #FBFBFD;
    min-height: 100vh;

    &.w-full{
        .section{
            width: 100%;
            margin-left: 0;
        }
    }

    .section{
        width: calc(100% - 240px);
        position: relative;
        margin-left: 240px;

        .intro{
            width: 100%;
            height: 100vh;
            position: relative;
            display: flex;
            align-items: center;
            background-image: linear-gradient( rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.68) ),url("@/assets/images/bg_login.png");
            background-size: cover;
        }

        .inner{
            width: 1300px;
            margin: 0 auto;

            .notice{
                width: 100%;
                padding: 10px 0;
                font-weight: 700;
                font-size: $font40;
            }
        }

        .box{
            padding: 160px 0;

            &-title{
                display: flex;
                justify-content: space-between;
            }

            .grey-box {
                padding: 20px 10px;
                position: relative;
                display: flex;

                div + div {
                    margin-left: 10px;
                }

                .flex{
                    @include my-auto;
                    right: 10px;
                    align-items: center;

                    .small{
                        width: 150px;
                    }

                    span{
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}
</style>
