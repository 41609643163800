import { getAxiosData, getCommas } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 정산 현황 리스트
 */
export async function getCalcMngList(params)
{
    console.log(params);
    const router = useRouter();

    try 
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/calcList/getList", params);

        let dep = 0;
        let pay = 0;
        let com = 0;

        console.log(res);

        const list = res.data['list'].map((item) => {
            if(item.organAdmin === 'E' || item.organAdmin === '')
            {
                dep++;
            }

            if(item.instAdmin === 'E' || item.instAdmin === '')
            {
                pay++;
            }

            if(item.organAdmin === 'Y' && item.instAdmin === 'Y')
            {
                com++;
            }

            return {
                mtCd        : item.mtCd,
                ogCd        : item.ogCd,
                lcCd        : item.lcCd,
                lcState     : item.state,
                subject     : item.subject,
                state       : item.state === 'Y' ? '완료' : (item.state === 'E' ? '진행' : '대기'),
                ogNm        : item.ogNm,
                name        : item.name,
                regDt       : item.regDt,
                lectPeriod  : item.lectPeriod,
                instAdmin   : item.instAdmin,
                organAdmin  : item.organAdmin,
                inst        : item.instAdmin === 'Y' ? getCommas(Number(item.instCalc)) : (item.instAdmin === 'N' ? '지급거부' : (item.instAdmin === '' ? '지급대기' : item.instAdmin)),
                organ       : item.organAdmin === 'Y' ? getCommas(Number(item.organCalc)) : (item.organAdmin === 'N' ? '입금거부' : (item.organAdmin === '' ? '입금대기' : item.organAdmin)),
                dayPay      : item.dayPay === 'Y' ? '사용' : '',
                amt         : getCommas(Number(item.amt))
            }
        })

        console.log(list);
        
        const filters = [
            { title : '입금대기', count : dep },
            { title : '지급대기', count : pay },
            { title : '정산완료', count : com },
            { title : '전체', count : list.length }
        ];

        store.commit('calcMng/setFilters', filters);
        store.commit("calcMng/setAllList", list);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 기관 정산 상세정보
 */
export async function getCalcMngOrganInfo(params)
{
    console.log(params);
    const router = useRouter();

    try 
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/calcList/getOrganInfo", params);

        console.log(res);

        const lcInfo  = res.data['lcInfo'];
        const payInfo = res.data['payInfo'];
        
        const info = {
            lcInfo      : lcInfo,
            payDt       : payInfo['payDt'] ? payInfo['payDt'] : '',
            totalAmt    : Number(payInfo['totalAmt']),
            couponNm    : '',
            couponAmt   : '',
            point       : {
                own : '',
                use : ''
            },
            calcAmt     : Number(payInfo['calcAmt']),
            payGb       : payInfo['payGb'] ? (payInfo['payGb'] === 'A' ? '계좌이체' : '신용/체크카드') : '', /** 계좌이체 : 'A' / 신용/체크카드 : 'C' */
            bankNm      : payInfo['bankNm'],
            bankAcc     : payInfo['bankAcc'],
            bankNum     : payInfo['bankNum'],
            payDoc      : payInfo['payDoc'] ? (payInfo['payDoc'] === 'T' ? '세금계산서' : '현금영수증') : '', /** 세금계산서 : 'T' / 현금영수증 : 'C' */
            einNm       : payInfo['contents'] ? payInfo['contents']['einNm'] : '',
            einNum      : payInfo['contents'] ? payInfo['contents']['einNum'] : '',
            einType     : payInfo['contents'] ? payInfo['contents']['einType'] : '',
            einItem     : payInfo['contents'] ? payInfo['contents']['einItem'] : '',
            ceoNm       : payInfo['contents'] ? payInfo['contents']['ceoNm'] : '',
            addr        : payInfo['contents'] ? payInfo['contents']['addr'] : '',
            email       : payInfo['contents'] ? payInfo['contents']['email'] : '',
            cashNum     : payInfo['contents'] ? payInfo['contents']['cashNum'] : '',
            adminYn     : payInfo['adminYn']
        }

        store.commit('calcMng/setOrganInfo', info);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 강사 정산 상세정보
 */
export async function getCalcMngInstInfo(params)
{
    console.log(params);
    const router = useRouter();

    try 
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/calcList/getInstInfo", params);

        console.log(res);

        const lcInfo  = res.data['lcInfo'];
        const payInfo = res.data['payInfo'];

        const info = {
            lcInfo      : lcInfo,
            calcGb      : payInfo['calcGb'] === 'N' ? '일반정산' : '데이페이',
            totalAmt    : Number(payInfo['totalAmt']),
            charge      : payInfo['charge'],
            chargeAmt   : Number(payInfo['totalAmt']) - Number(payInfo['calcAmt']),
            calcAmt     : Number(payInfo['calcAmt']),
            calcMean    : '계좌이체',
            bankNm      : payInfo['bankNm'],
            bankAcc     : payInfo['bankAcc'],
            bankNum     : payInfo['bankNum'],
            docEnum     : payInfo['docEnum'] ? (payInfo['docEnum'] === 'T' ? '세금계산서' : '사업소득세 3.3%') : '',
            einNm       : payInfo['contents'] ? payInfo['contents']['einNm'] : '',
            einNum      : payInfo['contents'] ? payInfo['contents']['einNum'] : '',
            einType     : payInfo['contents'] ? payInfo['contents']['einType'] : '',
            einItem     : payInfo['contents'] ? payInfo['contents']['einItem'] : '',
            ceoNm       : payInfo['contents'] ? payInfo['contents']['ceoNm'] : '',
            addr        : payInfo['contents'] ? payInfo['contents']['addr'] : '',
            email       : payInfo['contents'] ? payInfo['contents']['email'] : '',
            adminYn     : payInfo['adminYn']
        };

        store.commit('calcMng/setInstInfo', info);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}