import { createWebHistory, createRouter } from "vue-router";
import store from "@/store"; // Vuex store import

import AgencyMemMng from "@/views/AgencyMemMng.vue";
import TeacherMemMng from "@/views/TeacherMemMng.vue";
import AgencyInfo from "@/views/AgencyInfo.vue";
import TeacherInfo from "@/views/TeacherInfo.vue";
import AnnounceMng from "@/views/AnnounceMng.vue";
import AnnounceDetail from "@/views/AnnounceDetail.vue";
import CalcMng from "@/views/CalcMng.vue";
import CalcInstDetail from "@/views/CalcInstDetail.vue";
import CalcOrganDetail from "@/views/CalcOrganDetail.vue";
import NoticeList from "@/views/NoticeList.vue";
import NoticeUpdate from "@/views/NoticeUpdate.vue";
import ContactList from "@/views/ContactList.vue";
import ContactMng from "@/views/ContactMng.vue";
import AlarmList from "@/views/AlarmList.vue";
import AlarmUpdate from "@/views/AlarmUpdate.vue";
import TosList from "@/views/TosList.vue";
import TosUpdate from "@/views/TosUpdate.vue";
import AdverList from "@/views/AdverList.vue";
import MainLogin from "@/views/login/MainLogin.vue";
import JoinAgree from "@/views/login/JoinAgree.vue";
import JoinFirst from "@/views/login/JoinFirst.vue";
import JoinSecond from "@/views/login/JoinSecond.vue";

const routes = [
    {
        path: '/',
        name: "MainLogin",
        component: MainLogin
    },
    {
        path: '/login',
        name: "MainLogin",
        component: MainLogin
    },
    {
        path: '/join/agree',
        name: "JoinAgree",
        component: JoinAgree
    },
    {
        path: '/join/first',
        name: "JoinFirst",
        component: JoinFirst
    },
    {
        path: '/join/second',
        name: "JoinSecond",
        component: JoinSecond
    },
    {
        path: '/agencyMemMng',
        name: "AgencyMemMng",
        component: AgencyMemMng
    },
    {
        path: '/teacherMemMng',
        name: "TeacherMemMng",
        component: TeacherMemMng
    },
    {
        path: '/agencyInfo/:oaCd',
        name: "AgencyInfo",
        component: AgencyInfo
    },
    {
        path: '/teacherInfo/:iaCd',
        name: "TeacherInfo",
        component: TeacherInfo
    },
    {
        path: '/announceMng',
        name: "AnnounceMng",
        component: AnnounceMng
    },
    {
        path: '/announceDetail',
        name: "AnnounceDetail",
        component: AnnounceDetail
    },
    {
        path: '/calcMng',
        name: "CalcMng",
        component: CalcMng
    },
    {
        path: '/calcOrganDetail/:mtCd/:ogCd',
        name: "CalcOrganDetail",
        component: CalcOrganDetail
    },
    {
        path: '/calcInstDetail/:mtCd',
        name: "CalcInstDetail",
        component: CalcInstDetail
    },
    {
        path: '/noticeList',
        name: "NoticeList",
        component: NoticeList
    },
    {
        path: '/noticeUpdate/:ikey?',
        name: 'NoticeCreate',
        component: NoticeUpdate
    },
    {
        path: '/contactList',
        name: "ContactList",
        component: ContactList
    },
    {
        path: '/contactMng/:ikey',
        name: "ContactMng",
        component: ContactMng
    },
    {
        path: '/alarmList',
        name: "AlarmList",
        component: AlarmList
    },
    {
        path: '/alarmUpdate/:notiCd?',
        name: "AlarmUpdate",
        component: AlarmUpdate
    },
    {
        path: '/tosList',
        name: "TosList",
        component: TosList
    },
    {
        path: '/tosUpdate/:ikey?',
        name: "TosUpdate",
        component: TosUpdate
    },
    {
        path: '/adverList',
        name: "AdverList",
        component: AdverList
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const login = store.state.login.login;

    if(login === 'Y')
    {
        next();
    }
    else
    {
        if(to.name !== 'MainLogin')
        {
            next('/login');
        }
        else
        {
            next();
        }
    }
});

export default router