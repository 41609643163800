import { getAxiosData } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description 문의하기 리스트
 */
export async function getContactList(params)
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/faq/getList', params);
        console.log(res);
        const list = res.data.map((item, index) => {
            return {
                ikey        : item.ikey,
                num         : index+1,
                contents    : item.contents,
                writer      : item.writer,
                regDt       : item.regDt,
                answerNm    : item.answerNm ? item.answerNm : '',
                answerDt    : item.answerDt ? item.answerDt : '',
                answerYn    : item.answerNm ? '완료' : ''
            }
        });

        store.commit('contact/setList', list);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 문의하기 정보
 */
export async function getContactInfo(params) 
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/faq/getInfo', params);
        const info = {
            email       : res.data['email'],
            gb          : res.data['gb'],
            contents    : res.data['contents'],
            tel         : res.data['tel'],
            answerNm    : res.data['answerNm'] ? res.data['answerNm'] : '',
            answerDt    : res.data['answerDt'] ? res.data['answerDt'] : '',
            history     : res.data['memoList']
        };

        store.commit('contact/setInfo', info);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}