<template>
    <div class="inner box">
        <NoticeBox :lists="noticeList"/>

        <div class="detail-info">
            <div class="detail-thumb">
                <SliderImage :images="getImage"/>
                <!-- <img :src="getImage" alt="" title=""/> -->
            </div>

            <div class="detail-text">
                <h3>
                    {{ info['subject'] }}
                    <span>{{ getDday }}</span>
                </h3>
                <p>{{ info['regDt'] }} 작성</p>
                <ul>
                    <li>강의분야 : {{ getMainLecture(info['mainFieldCd']) }} / {{ getLecture(info['fieldCd']) }}</li>
                    <li>강의일시 : {{ info['lectPeriod'] }} / {{ info['lectTime'] }}( {{ info['totalTime'] }}시간 )</li>
                    <li>강사비 : {{ info['amt'] }}(시간당 : {{ info['estAmt'] }}원)</li>
                    <li>지역 : {{ info['area1']+' / '+info['area2'] }}</li>
                </ul>
            </div>
        </div>

        <ul class="detail-spec">
            <!-- <li>
                <span>일시</span> : {{ info['subject'] }}
            </li> -->
            <li>
                <span>수강대상</span> : {{ info['target'] }}
            </li>
            <li>
                <span>강의장소</span> : {{ info['addr'] }}
            </li>
            <li>
                <span>제공장비</span> : {{ info['lectEq'] }}
            </li>
            <li>
                <span>키워드</span> : {{ info['keyword'] }}
            </li>
            <li>
                <span>접수기간</span> : {{ info['regPeriod'] }}
            </li>
        </ul>

        <div class="detail-description" >
            <span v-html="info['lectMemo']"></span>
        </div>

        <ul class="detail-spec">
            <h2>제출서류</h2>
            <li>
                <span>필수사항 : {{ info['reqDoc'] }}</span>
            </li>
            <li>
                <span>선택사항 : {{ info['etcDoc'] }}</span>
            </li>
        </ul>

        <ul class="detail-spec">
            <h2>제출서류 다운로드</h2>
            <li class="orange" v-for="(item, index) in info['fileList']" :key="index">
                <a :href="`${downloadPath}/${item['filePath']}`" download>제출파일{{index + 1}}</a>
            </li>
        </ul>

        <ul class="detail-spec">
            <h2>기관정보</h2>
            <li>
                <span>기관명 : {{ info['ogNm'] }}</span>
            </li>
            <li>
                <span>위치 : {{ info['ogAddr'] }}</span>
            </li>
            <li>
                <span>이메일 : {{ info['chEmail'] }}</span>
            </li>
            <li>
                <span>담당자 : {{ info['chPerNm'] }}</span>
            </li>
            <li>
                <span>전화번호 : {{ info['chPerTel'] }}</span>
            </li>
        </ul>

        <div v-if="gubun === 'E' || gubun === 'M'" class="button-wrapper">
            <BaseButton class="grey large" buttonText="게시 반려" @click="popStat = true"/>
            <BaseButton class="large" buttonText="공개 게시" @click="getResultSet('Y')"/>
        </div>
    </div>
    <WhiteAlert v-if="popStat" class="blue" title="반려사유" buttonText="반려" @close-popup="popStat = false" @confirm-popup="getResultSet('N')">
        <TextareaBox class="mb-25" :inputValue="inputMemo" placeholder="반려사유" @input="getInputMemo"/>
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import NoticeBox from "@/components/common/NoticeBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import SliderImage from "@/components/SliderImage.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getAnnounceMngInfo } from "@/assets/js/announceMng";
import { getAxiosData } from "@/assets/js/function";

export default {
    name      : "AnnounceDetail",
    components: { BaseButton, NoticeBox, WhiteAlert, TextareaBox, SliderImage, Confirm },
    computed: {
        ...mapState('data', {
            mainLecture : state => state.data.mainLecture,
            lecture     : state => state.data.lecture
        }),
        ...mapState('announceMng', {
            info    : state => state.announceMng.info
        }),
        getImage() {
            const file = [];

            this.info['imgList'].map((item) => {
                file.push({
                    url : item.fileNm
                });
            })

            return file;
        },
        getDday() {
            const today      = new Date();
            const date       = this.info['regPeriod'].split(' ~ ');
            const targetDate = new Date(date[1]);

            const timeDiff = targetDate.getTime() - today.getTime();
            const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

            return daysDiff > 0 ? (daysDiff <= 3 ? '마감임박' : `D-${daysDiff}`) : 'D-Day';
        }
    },
    created() {
        this.getInfo();
    },
    data() {
        return {
            noticeList          : [
                {content : "입사지원 서류에 허위사실이 발견될 경우, 채용확정 이후라도 채용이 취소될 수 있습니다."},
                {content : "지원서와 함께 제출된 모든 개인정보는 채용 절차에만 사용되며, 다른 목적으로 이용되지 않습니다."},
                {content : "지원서에 기재된 내용과 채용시 인터뷰에서 확인되는 내용이 상이할 경우, 채용심사에서 불이익을 받을 수 있습니다."},
                {content : "사문서 위조 시 법적 처벌에 대해 주의해주세요. 입사지원서 및 관련 서류에 허위 정보를 기재하거나 위조하는 행위는 형사처벌의 대상이 될 수 있으며, 이로 인해 채용이 취소될 수 있습니다."},
            ],
            gubun               : this.$route.query.state,
            st                  : 'Y',
            resultYn            : 'N',
            compYn              : 'N',
            downloadPath        : 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            inputMemo           : '',
            popStat             : false
        }
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.resultYn === 'Y')
            {
                this.getResult();
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                history.back();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getMainLecture(value) {
            const mainLecture = this.mainLecture.find(item => item['value'] === value);

            return mainLecture['name'];
        },
        getLecture(value) {
            const lecture = this.lecture.find(item => item['value'] === value);

            return lecture['name'];
        },
        getInputMemo() {
            this.inputMemo = event.target.value;
        },
        getInfo() {
            const params = {
                lcCd  : this.$route.query.lcCd,
                state : this.$route.query.state
            };
            
            getAnnounceMngInfo(params);
        },
        getResultSet(st) {
            this.st = st;

            switch(st)
            {
                case 'Y':
                    this.getConfirmSet('confirm', '승인', '승인 처리하시겠습니까?');
                break;
                case 'N':
                    this.getConfirmSet('confirm', '반려', '반려 처리하시겠습니까?');
                break;
            }

            this.resultYn = 'Y';
        },
        async getResult() {
            this.resultYn = 'N';

            try
            {
                let url = '';

                if(this.info['lcCdOri'] === '')
                {
                    url = 'https://data.wecls.com/admin/lectList/stateChange';
                }
                else
                {
                    url = 'https://data.wecls.com/admin/lectList/modifyLect';
                }

                const params = {
                    lcCd  : this.$route.query.lcCd,
                    state : this.st,
                    memo  : this.inputMemo
                };

                const res = await getAxiosData(url, params);

                console.log(res);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '확인', '처리되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.detail {

    &-info {
        margin: 46px 0;
        display: flex;
    }

    &-thumb {
        width: 374px;
        height: 374px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 50px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-text {
        width: calc(100% - 424px);
        position: relative;

        h3 {
            font-size: $font22;
            display: flex;
            align-items: center;

            span {
                background-color: $red-color-lv-5;
                color: $bk-color-lv-3;
                border-radius: 4px;
                padding: 1px 4px;
                display: inline-block;
                margin-left: 14px;
                font-size: $font16;
            }
        }

        p {
            color: $text-color-lv-4;
            font-size: $font18;
            font-weight: 500;
        }

        ul {
            margin-top: 50px;
            color: $text-color-lv-0;
            font-size: $font18;
            font-weight: 500;
            line-height: $font48;
        }

        div {
            position: absolute;
            text-align: right;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;

            svg{
                cursor: pointer;
                border: 1px soldi $bk-color-lv-6;
                color: $text-color-lv-3;
                font-size: $font28;
                margin-right: 20px;

                &.active{
                    color: $blue-color-lv-4;
                }
            }

            button {
                border-radius: 30px;
                background-color: $button-color-lv-1;
                padding: 16px 26px;
                color: $text-color-lv-5;
                font-size: $font16;
                font-weight: 700;

                &.orange {
                    background-color: $red-color-lv-5;
                    color: $bk-color-lv-3;
                }
            }

            button + button {
                margin-left: 14px;
            }
        }
    }

    &-spec {
        padding: 20px 0;
        font-size: $font20;
        line-height: $font40;
        border-bottom: 1px solid $line-color-lv-3;

        h2 {
            font-size: $font28;
            margin-bottom: 15px;
        }

        li {
            color: $text-color-lv-5;

            span {
                color: $text-color-lv-0;
            }

            a[download] {
                color: $red-color-lv-5;
            }
        }
    }

    &-description {
        margin: 46px 0;
    }
}

.button-wrapper{
    text-align: center;
    margin-top: 80px;
}
</style>