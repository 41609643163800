import { getAxiosData } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description 공지사항 리스트
 */
export async function getAlarmList(params)
{
    console.log(params);
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/notiList/getList', params);

        console.log(res);

        let organ = 0;
        let inst  = 0;

        const list = res.data.map((item, index) => {
            if(item.userGb === 'O')
            {
                organ++;
            }
            else if(item.userGb === 'I')
            {
                inst++;
            }

            return {
                notiCd  : item.notiCd,
                num     : index+1,
                subject : item.subject,
                gb      : item.gb === 'U' ? '업데이트' : (item.gb === 'N' ? '일반' : '긴급'),
                userGb  : item.userGb === 'A' ? '전체' : (item.userGb === 'O' ? '기관' : '강사'),
                regDt   : item.regDt
            }
        });

        const filters = [
            { title : '기관', count : organ },
            { title : '강사', count : inst },
            { title : '전체', count : list.length }
        ];

        store.commit('alarm/setFilters', filters);
        store.commit('alarm/setAllList', list);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 알림 정보
 */
export async function getAlarmInfo(params) 
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/notiList/getInfo', params);

        console.log(res);

        const info = {
            subject  : res.data['subject'],
            userGb   : { value : res.data['userGb'], name : (res.data['userGb'] === 'A' ? '전체' : (res.data['userGb'] === 'O' ? '기관' : '강사')) },
            contents : res.data['contents'],
            memo     : res.data['memo']
        };

        console.log(info);

        store.commit('alarm/setInfo', info);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}