<template>
    <div class="inner box">
        <div class="box-title">
            <h2>광고관리</h2>
        </div>
        <div class="margin grey-box">
            <SelectBox :labelText="'간편 조회'" :selectOptions="dateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>
        <BasicTable :columns="tableList" :rows="list" class="margin" @row-click="getDetail"/>
    </div>

    <WhiteAlert v-if="popupStat" class="blue" title="광고정보" buttonText="광고저장" @close-popup="popupStat = false" @confirm-popup="getResultSet">
        <div class="flex mb-10">
            <label>광고 위치</label>
            <InputBox :inputType="'text'" :inputValue="'랜딩페이지 메인'" readonly disabled />
        </div>

        <div class="flex mb-10">
            <label>광고명</label>
            <InputBox :inputId="'name'" inputType="text" :inputValue="info['name']" />
        </div>

        <div class="flex mb-10">
            <label>URL 링크</label>
            <InputBox :inputId="'url'" inputType="text" :inputValue="info['url']" />
        </div>

        <div class="flex mb-25 mt-25">
            <label>광고 이미지</label>
            <RepImage imgTitle="관련 사진" :inputId="'img'" :imageView="info['img']['file']" @file-selected="getFileSelect" @file-cleared="getFileDelete"/>
        </div>

        <TextareaBox class="mb-25" inputId="" inputNm="" placeholder="메모 내용" />
    </WhiteAlert>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import SelectBox from "@/components/input/SelectBox.vue";
import DateBox from "@/components/input/DateBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import WhiteAlert from "@/components/alert/WhiteAlert.vue";
import InputBox from "@/components/input/InputBox.vue";
import RepImage from "@/components/input/RepImage.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { getAdverList, getAdverInfo } from "@/assets/js/adver";
import { mapState } from "vuex";
import { getDate, getConvertDate, getAxiosData } from "@/assets/js/function";

export default {
    name      : "AdverList",
    components: {TextareaBox, RepImage, InputBox, WhiteAlert, BasicTable, DateBox, SelectBox, Confirm},
    computed: {
        ...mapState('data', {
            dateType : state => state.data.dateType
        }),
        ...mapState('adver',{
            search  : state => state.adver.search,
            list    : state => state.adver.list,
            type    : state => state.adver.type,
            info    : state => state.adver.info
        })
    },
    data() {
        return {
            tableList: [
                {key: "num", header: "번호", class: "w7"},
                {key: "name", header: "광고명", class: "blue"},
                {key: "location", header: "광고위치", class: "w10"},
                {key: "clickCnt", header: "클릭수", class: "w8"},
                {key: "regNm", header: "작성자", class: "w12"},
                {key: "regDt", header: "등록일시", class: "w8"},
            ],
            popupStat           : false,
            ikey                : '',
            resultYn            : 'N',
            compYn              : 'N',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
        }
    },
    created() {
        this.getList();
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                this.getList();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            this.getList();
        },
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            this.getList();
        },
        getDetail(params){
            this.ikey      = params['ikey'];
            this.popupStat = !this.popupStat;

            getAdverInfo({ikey : params['ikey']});
        },
        getFileSelect({inputId, file}) {
            console.log(inputId);
            console.log(file);
        },
        getList(){
            const params = {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            };

            getAdverList(params);
        },
        getResultSet() {
            this.getConfirmSet('confirm', '저장', '광고를 저장하시겠습니까?');
            this.resultYn = 'Y';
        },
        async getResult() {
            this.resultYn = 'N';

            const formData = new FormData();

            formData.append('ikey',     this.ikey);
            formData.append('name',     this.info['name']);
            formData.append('url',      this.info['url']);
            formData.append('memo',     this.info['memo']);

            if(this.info['img']['file'] !== null)
            {
                formData.append('img', this.info['img']['file']);
            }

            try
            {
                const res = await getAxiosData('https://data.wecls.com/admin/adver/dataProc', formData);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '저장', '저장되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.flex{
    align-items: center;
    label{
        width: 100px;
        font-size: $font14;
        color: $text-color-lv-1;
        font-weight: 400;
        text-align: left;
    }

    .input{
        width: calc(100% - 100px);
        color: $text-color-lv-1;
    }
}

.margin {
    margin-top: 46px;
}
</style>