<template>
    <div class="sort">
        <div class="sort-content">
            <div class="sort-list" v-for="(sort, index) in sorts" :key="index">
                <h5>
                    {{ sort.title }}
                </h5>
                <p :class="sort.color">
                    {{ sort.count }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SortListCard",
    props: {
        sorts    : Array,
        showTitle: Boolean,
        title    : String,
    }
}
</script>

<style lang="scss" scoped>
.sort{
    &-content{
        width: 100%;
        display: flex;
        text-align: center;
        border-bottom: 1px solid $line-color-lv-4;
        padding: 20px 0;
    }

    &-list {
        width: 100%;
    }

    h5 {
        font-size: $font18;
        color: $text-color-lv-5;
        font-weight: 400;
        margin-bottom: 24px;
    }

    p {
        font-size: $font28;
        color: $text-color-lv-0;
        font-weight: 700;

        &.blue {
            color: $blue-color-lv-4;
        }
    }
}
</style>