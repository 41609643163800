<template>
    <div class="inner box">
        <h2>이용약관 등록</h2>
        <LabelTable title="기본정보" class="margin">
            <tr v-if="ikey">
                <th>이력번호</th>
                <td class="p12">
                    <InputBox :inputValue="'약관변경'+ikey" class="medium" disabled readonly />
                </td>
            </tr>
            <tr v-if="ikey">
                <th>등록 담당자</th>
                <td class="p12">
                    <InputBox :inputValue="info['name']" class="medium" disabled readonly />
                </td>
            </tr>
            <tr v-if="ikey">
                <th>등록일시</th>
                <td class="p12">
                    <InputBox :inputValue="info['regDt']" class="medium" disabled readonly />
                </td>
            </tr>
            <tr>
                <th>업데이트 사유</th>
                <td class="p12">
                    <TextareaBox :inputId="'memo'" :inputValue="info['memo']" @input="getInputVal"/>
                </td>
            </tr>
        </LabelTable>

        <div class="white-box margin">
            <!-- <h3 class="mb-20 mt-10">[필수] 만 14세 이상입니다.</h3>
            <TextEditor v-model="info['terms1']"/> --> <!-- 어나더데이 측 삭제 요청 => 2024/01/29 김원명 -->
            <h3 class="mb-20 margin">[필수] 이용약관 동의</h3>
            <TextEditor v-model="info['terms2']"/>
            <h3 class="mb-20 margin">[필수] 개인정보 수집 이용동의</h3>
            <TextEditor v-model="info['terms3']"/>
            <h3 class="mb-20 margin">[선택사항] 마케팅 정보 수신 동의</h3>
            <TextEditor v-model="info['terms4']"/>
        </div>

        <div class="button-wrapper mt-10">
            <BaseButton v-if="!ikey" buttonText="등록하기" class="rounded4 regular" @click="getResultSet"/>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import InputBox from "@/components/input/InputBox.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import TextEditor from "@/components/common/TextEditor.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getTosInfo } from "@/assets/js/tos";
import { getAxiosData } from "@/assets/js/function";

export default {
    name: "TosUpdate",
    components: {BaseButton, TextEditor, TextareaBox, InputBox, LabelTable, Confirm},
    computed: {
        ...mapState('tos', {
            info    : state => state.tos.info
        })
    },
    created() {
        this.$store.commit('tos/setReset');
        this.getInfo();
    },
    data() {
        return{
            resultYn            : 'N',
            compYn              : 'N',
            ikey                : this.$route.params.ikey,
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : ''
        }
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                this.$router.go(-1);
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInfo() {
            if(this.ikey)
            {
                getTosInfo({ ikey : this.ikey });
            }
        },
        getInputVal() {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getResultSet() {
            this.getConfirmSet('confirm', '저장', '약관을 저장하시곘습니까?');
            this.resultYn = 'Y';
        },
        async getResult() {
            this.resultYn = 'N';

            try
            {
                const params = {
                    terms1  : this.info['terms1'],
                    terms2  : this.info['terms2'],
                    terms3  : this.info['terms3'],
                    terms4  : this.info['terms4'],
                    memo    : this.info['memo']
                };

                const res = await getAxiosData('https://data.wecls.com/admin/terms/dataProc', params);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '확인', '처리되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}

.white-box{
    border-radius: 4px;
    background-color: $bk-color-lv-3;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.20);
    padding: 12px 20px 100px 20px;

    h3{
        font-size: $font16;
    }
}

.button-wrapper{
    text-align: center;
    background-color: $bk-color-lv-3;
    padding: 40px 0;
}
</style>