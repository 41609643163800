import { getAxiosData } from "@/assets/js/function";
import store from '@/store';
import { useRouter } from "vue-router";

/**
 *  @description 광고관리 리스트
 */
export async function getAdverList(params)
{
    console.log(params);
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/adver/getList', params)

        console.log(res);
        const list = res.data.map((item, index) => {
            return {
                ikey        : item.ikey,
                num         : index+1,
                name        : item.name,
                location    : '',
                clickCnt    : 0,
                regNm       : '',
                regDt       : item.regDt
            }
        });

        store.commit('adver/setList', list);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 광고 정보
 */
export async function getAdverInfo(params) 
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData('https://data.wecls.com/admin/adver/getInfo', params);
        const info = {
            name        : res.data['name'],
            url         : res.data['url'],
            memo        : res.data['memo'],
            img         : {
                text    : res.data['fileName'],
                file    : res.data['filePath']
            }
        };

        store.commit('adver/setInfo', info);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}