<template>
    <div class="inner box">
        <h2>공고 게시물 현황</h2>
        <FilterList :filters="filters" class="mt-80" @filter-changed="getListChange"/>
        <div class="margin grey-box">
            <SelectBox :labelText="'간편 조회'" :selectOptions="dateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>
        <BasicTable :columns="tableList" :rows="list" class="margin" @row-click="getDetail"/>
    </div>
</template>

<script>
import FilterList from "@/components/list/FilterList.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import DateBox from "@/components/input/DateBox.vue";
import BasicTable from "@/components/table/BasicTable.vue";

import { mapState } from "vuex";
import { getAnnounceMngList } from "@/assets/js/announceMng";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name      : "AnnounceMng",
    components: {BasicTable, DateBox, SelectBox, FilterList},
    computed: {
        ...mapState('data', {
            dateType  : state => state.data.dateType
        }),
        ...mapState('announceMng', {
            search  : state => state.announceMng.search,
            filters : state => state.announceMng.filters,
            allList : state => state.announceMng.allList,
            list    : state => state.announceMng.list
        })
    },
    created() {
        getAnnounceMngList(this.getParams());
    },
    data() {
        return {
            tableList : [
                { key: "subject", header: "강의명", class: "blue" },
                { key: "gbNm", header: "승인현황", class: "w8" },
                { key: "ogNm", header: "기관명", class: "w16" },
                { key: "instNm", header: "강사명", class: "w8" },
                { key: "regDt", header: "공고 일시", class: "w12" },
                { key: "lectPeriod", header: "매칭 수업일자", class: "w13" },
                { key: "volCnt", header: "지원자 현황", class: "w8" },
                { key: "amt", header: "금액", class: "w8" }
            ]
        }
    },
    methods: {
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];
            const date  = getDate(value);

            this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
            this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');

            getAnnounceMngList(this.getParams());
        },
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            getAnnounceMngList(this.getParams());
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            getAnnounceMngList(this.getParams());
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('announceMng/setList', this.allList);
            }
            else
            {
                const list = this.allList.filter((item) => {
                    return item.gbNm === params['title'];
                });

                this.$store.commit('announceMng/setList', list);
            }
        },
        getDetail(params) {
            this.$router.push({ name: 'AnnounceDetail', query: { lcCd: params['lcCd'], state: params['state'] } });
        },
        getParams(){
            return {
                startDt : this.search['startDt'],
                endDt   : this.search['endDt'],
                start   : 0,
                limit   : 50
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.mt-80 {
    margin-top: 80px;
}

.margin {
    margin-top: 46px;
}
</style>