import { getAxiosData } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 이용약관 리스트
 */
export async function getTosList(params)
{
    const router = useRouter();

    try 
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/terms/getList", params);

        console.log(res);

        const list = res.data.map((item, index) => {
            return {
                ikey    : item.ikey,
                num     : index + 1,
                code    : '약관변경'+item.ikey,
                name    : item.name,
                regDt   : item.regDt,
                memo    : item.memo
            };
        });

        console.log(list);

        store.commit('tos/setList', list);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 이용약관 정보
 */
export async function getTosInfo(params)
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/terms/getInfo", params);
        const info = res.data;

        console.log(info);

        const data = {
            name   : info['name'],
            regDt  : info['regDt'],
            memo   : info['memo'],
            terms1 : info['terms1'],
            terms2 : info['terms2'],
            terms3 : info['terms3'],
            terms4 : info['terms4']
        }

        store.commit('tos/setInfo', data);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}