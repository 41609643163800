import { getAxiosData, getCommas } from "@/assets/js/function";
import store from "@/store";
import { useRouter } from "vue-router";

/**
 * @description 기관 리스트
 */
export async function getAgencyMemMngList(params)
{
    const router = useRouter();

    try 
    {
        let normal  = 0;
        let out     = 0;
        let draw    = 0;

        const list = [];
        const res  = await getAxiosData("https://data.wecls.com/admin/organList/getList", params);

        console.log(res);

        /** 카운트 헤더 */
        const headers = [
            { title : '이번달 신규가입', count : res.data['cnt']['newOrgan']+'명' },
            { title : '이번달 탈퇴', count : res.data['cnt']['outOrgan']+'명' },
            { title : '기관정보 가입승인 대기', count : res.data['cnt']['unReg']+'건' },
            { title : '기관정보 업데이트 대기', count : res.data['cnt']['reging']+'건' },
            { title : '기관정보 업데이트 승인', count : res.data['cnt']['reged']+'건' }
        ];

        store.commit("agencyMemMng/setHeaders", headers);

        let gbNm;
        let stNm;

        res.data['list'].map((item) => {
            switch(item.activeGbNm)
            {
                case '활동':
                    gbNm = '일반회원';
                    normal++;
                break;
                case '정지':
                    gbNm = '정지회원';
                    out++;
                break;
                case '탈퇴':
                    gbNm = '탈퇴회원';
                    draw++;
                break;
            }

            if(item.state)
            {
                switch(item.state)
                {
                    case 'E':
                        stNm = '신청';
                    break;
                    case 'Y':
                        stNm = '승인완료';
                    break;
                    case 'N':
                        stNm = '승인불가';
                    break;
                }
            }
            else
            {
                stNm = '미신청';
            }

            list.push({
                oaCd            : item.oaCd,
                platform        : item.platform,
                email           : item.id,
                channel         : item.channel === 'D' ? '일반' : (item.channel === 'K' ? '카카오' : '네이버'),
                name            : item.name,
                activeGbNm      : item.activeGbNm,
                gbNm            : gbNm,
                stNm            : stNm,
                modDt           : item.modDt ? item.modDt.slice(0, -9) : '',
                regDt           : item.regDt ? item.regDt.slice(0, -9) : '',
                recLogin        : item.recLogin,
                loginCnt        : item.loginCnt+'회'
            });
        });

        const filters = [
            { title : '일반회원', count : normal },
            { title : '정지회원', count : out },
            { title : '탈퇴회원', count : draw },
            { title : '전체', count : list.length }
        ];

        store.commit('agencyMemMng/setFilters', filters);
        store.commit("agencyMemMng/setAllList", list);
    }
    catch (error) 
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

/**
 * @description 기관 정보
 */
export async function getAgencyMemMngInfo(params)
{
    const router = useRouter();

    try
    {
        const res  = await getAxiosData("https://data.wecls.com/admin/organList/getInfo", params);
        const info = res.data['info'];

        console.log(info);

        let agree       = info['markAgree'];
        let resultArray = [];
        
        for (let key in agree) 
        {
            switch (agree[key]) 
            {
                case 'Y':
                    switch (key) 
                    {
                        case 'app':
                            resultArray.push('앱 푸시');
                        break;
                        case 'email':
                            resultArray.push('Email');
                        break;
                        case 'kakao':
                            resultArray.push('카카오 알림톡');
                        break;
                        case 'sms':
                            resultArray.push('SMS');
                        break;
                    }
                break;
            }
        }

        const fileOther = [];

        if(info['etcFile'] !== null)
        {
            info['etcFile'].map((item) => {
                fileOther.push({
                    text : item.fileNm,
                    file : item.filePath
                });
            })
        }

        const data = {
            ogCd        : info['ogCd'],
            platform    : '기관',
            channel     : info['channel'] === 'D' ? '일반' : (info['channel'] === 'N' ? '네이버' : '다음'),
            joinDt      : info['regDt'],
            loginDt     : info['recLogin'],
            id          : info['id'],
            name        : info['name'],
            tel         : info['tel'],
            agree       : resultArray.join(' / '),
            img1        : info['idPic'] ? info['idPic']['filePath'] : null,
            img2        : info['expPic'] ? getFileCheck(info['expPic'], null, '2', 'filePath') : null,
            img3        : info['expPic'] ? getFileCheck(info['expPic'], null, '3', 'filePath') : null,
            img4        : info['expPic'] ? getFileCheck(info['expPic'], null, '4', 'filePath') : null,
            img5        : info['expPic'] ? getFileCheck(info['expPic'], null, '5', 'filePath') : null,
            cntry       : '대한민국',
            ogNm        : info['ogNm'],
            addr        : info['addr']+' '+info['addrDetail'],
            charPerNm   : info['charPerNm'],
            charPerTel  : info['charPerTel'],
            fax         : info['fax'],
            memo        : info['memo'],
            bankNm      : info['codeSubNm'],
            accNm       : info['bankNm'],
            account     : info['bankNum'],
            einNm       : info['einNm'],
            einNum      : info['einNum'],
            ceoNm       : info['ceoNm'],
            einType     : info['einType'],
            einItem     : info['einItem'],
            einEmail    : info['einEmail'],
            img6        : info['einPic'] ? info['einPic']['filePath'] : null,
            fileOther   : fileOther,
            lcCnt       : info['lcCnt'],
            matchCnt    : info['matchCnt'],
            compCnt     : info['compCnt'],
            compAmt     : getCommas(info['compAmt']),
            cancleCnt   : info['cancleCnt'],
            userMemo    : res.data['userMemo'],
            state       : info['state'],
            lastDt      : info['lastDt']
        }

        store.commit('agencyMemMng/setInfo', data);
    }
    catch(error)
    {
        console.log(error);
        const check = error.response.data.message.includes('NotAuthorizedException');

        if(error.response.data.status === 401 || check)
        {
            alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
            store.dispatch('login/logout');
            router.push('/login');
        }
        else
        {
            alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
        }
    }
}

function getFileCheck(data, val, seq, nm)
{
    const check = data.find(item => item.fileNo === seq);

    if(check)
    {
        return check[nm];
    }
    else
    {
        return val;
    }
}