<template>
    <div class="join intro">
        <MobileBox title="회원가입" showPrev>
            <InputBox labelText="이메일" :inputId="'id'"
                      :inputValue="''" :helperText="''" :class="''"
                      placeholder="이메일을 입력해주세요." @input="''"/>

            <InputBox labelText="비밀번호" :inputId="'pw'" :inputType="'password'"
                      :inputValue="''" :helperText="''" :class="''"
                      placeholder="비밀번호를 입력해주세요" @input="''"/>

            <InputBox labelText="비밀번호 확인" :inputId="'pwChk'" :inputType="'password'"
                      :inputValue="''" :helperText="''" :class="''"
                      placeholder="비밀번호를 입력해주세요" @input="''"/>

            <BaseButton class="grey w-full" buttonText="회원가입" @click="''" />
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="''" @getClose="''"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import InputBox from "@/components/input/InputBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

export default {
    name: "JoinFirst",
    components: {BaseButton, InputBox, MobileBox, Confirm},
    data() {
        return {
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            joinComplete        : 'N'
        }
    },
}
</script>

<style lang="scss" scoped>
.join{
    .mobile-content{
        div + div, div + button {
            margin-top: 24px;
        }
    }
}
</style>