<template>
    <table class="table">
        <colgroup>
            <col v-for="col in columns" :key="col.key" :class="col.class"/>
        </colgroup>

        <thead>
        <tr>
            <th v-for="col in columns" :key="col.key">
                {{ col.header }}
            </th>
        </tr>
        </thead>

        <tbody>
            <tr v-for="(row, index) in rows" :key="index">
                <td v-for="col in columns" :key="col.key" :class="col.class" @click="handleRowClick(row, col.class, col.key)">
                    <span v-if="col.type && col.type['gubun'] === 'button' && row[col.key] === 'E'">
                        <button @click="handleButtonClick(row, col.type['text'])">{{ col.type['text'] }}</button>
                    </span>
                    <span v-else>
                        {{ row[col.key] }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name : "BasicTable",
    props: {
        columns : Array,
        rows    : Array,
        rowClick: {
            type   : Boolean,
            default: false
        }
    },

    methods: {
        handleButtonClick(row, text) {
            this.$emit('button-click', row, text);
        },
        handleRowClick(row, colClass, key) {
            if(colClass.includes('blue'))
            {
                this.$emit('row-click', row, key);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;

    thead, tbody {
        tr {
            th, td {
                border-top: 1px solid $line-color-lv-2;
                border-bottom: 1px solid $line-color-lv-2;
                background-color: $bk-color-lv-3;
                font-size: $font14;
                font-weight: 400;
                text-align: left;
                @include ellipsis;
            }

            th {
                padding: 12px 10px;
                background-color: $table-color-lv-1;
            }

            td {
                padding: 8px 10px;

                button{
                    padding: 1px 7px;
                    background-color: $blue-color-lv-4;
                    color: $bk-color-lv-3;
                    text-align: center;
                    font-size: $font14;
                    border-radius: 4px;
                    border: 1px solid $line-color-lv-4;
                    width: 76px;
                }
            }
        }
    }
}
</style>