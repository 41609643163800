<template>
    <div class="rep">
        <img v-if="getImage" :src="getImage" alt="Preview"/>

        <label :for="inputId">
            <span>{{ imgTitle }}</span>
        </label>
        <input type="file" :id="inputId" :name="inputNm" accept="image/*"/>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import Confirm from "@/components/alert/Confirm.vue";

export default {
    name : "RepImage",
    components : { Confirm },
    props: {
        inputId     : String,
        inputNm     : String,
        imgTitle    : String,
        imageView   : String
    },
    data() {
        return {
            imagePreview        : null,
            uploadYn            : 'N',
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
        }
    },
    computed : {
        getImage(){
            if(this.imageView !== null && this.uploadYn === 'N')
            {
                return 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com/'+this.imageView;
            }
            else
            {
                return this.imagePreview;
            }
        }
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        RepImage(event) {
            this.uploadYn = 'Y';

            const file    = event.target.files[0];
            const maxSize = 20 * 1024 * 1024; // 20MB

            if(file.size > maxSize)
            {
                this.getConfirmSet('alert', '파일 크기 확인', '파일 크기는 20MB를 넘을 수 없습니다.');
                return false;
            }

            if (file) 
            {
                const reader  = new FileReader();
                reader.onload = () => {
                    this.imagePreview = reader.result;
                    this.$emit('file-selected', { inputId: this.inputId, file: file });
                };
                reader.readAsDataURL(file);
            }
            else 
            {
                this.imagePreview = null;
            }
        },
    },
    watch : {
        imageView(newImage){
            if(newImage !== null && this.uploadYn === 'N')
            {
                this.imagePreview = 'https://elasticbeanstalk-ap-northeast-2-627549176645.s3.ap-northeast-2.amazonaws.com/'+newImage;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.rep {
    width: 78px;
    height: 87px;
    border-radius: 6px;
    position: relative;
    background-color: $bk-color-lv-2;
    overflow: hidden;
    display: inline-block;
    margin-right: 20px;

    input {
        display: none;
    }

    label {
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;

        span {
            display: inline-block;
            padding: 6px 8px;
            position: absolute;
            left: 0;
            bottom: 0;
            border-radius: 0 6px 0 0;
            background: rgba(0, 0, 0, .5);
            color: $bk-color-lv-3;
            font-size: $font12;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    .close {
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 2rem;
        width: 16px;
        height: 16px;
        background: rgba(169, 173, 168, 0.30);
        cursor: pointer;
        backdrop-filter: blur(10px);

        img {
            width: 12px;
            height: 12px;
            margin: 2px auto 0 auto;
            display: block;
        }
    }
}
</style>