<template>
    <div class="box inner">
        <div class="box-title">
            <h2>문의하기 관리</h2>
        </div>

        <LabelTable title="문의내용" class="margin no-shadow">
            <!-- <tr>
                <th>이메일주소</th>
                <td>{{ info['email'] }}</td>
            </tr> -->
            <tr>
                <th>문의유형</th>
                <td>{{ info['gb'] }}</td>
            </tr>
            <tr>
                <th>문의 세부내용</th>
                <td>{{ info['contents'] }}</td>
            </tr>
            <tr>
                <th>담당자 이메일 / 연락처</th>
                <td>{{ info['tel'] }}</td>
            </tr>
        </LabelTable>

        <LabelTable title="상담관리" class="margin no-shadow">
            <tr>
                <th>답변완료 담당자</th>
                <td>{{ info['answerNm'] }}</td>
            </tr>

            <tr>
                <th>답변완료 일시</th>
                <td>{{ info['answerDt'] }}</td>
            </tr>

            <tr>
                <th>관리자 메모</th>
                <td class="p12">
                    <div>
                        <TextareaBox :inputValue="inputMemo" @input="getInputMemo"/>
                        <BaseButton v-if="info['answerNm'] === ''" buttonText="메모 저장" class="ml-10" @click="getResultSet('M')"/>
                    </div>
                </td>
            </tr>

            <tr>
                <th>메모 변경 히스토리</th>
                <td>
                    <p v-for="(item, index) in info['history']" :key="index">
                        {{ item.name }} / {{ item.regDt }} / 메모: {{ item.memo }}
                    </p>
                </td>
            </tr>
        </LabelTable>

        <div class="button-wrapper margin">
            <BaseButton class="grey large" buttonText="목록이동" @click="getBack"/>
            <BaseButton v-if="info['answerNm'] === ''" class="large" buttonText="문의하기 답변완료" @click="getResultSet('Y')"/>
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getContactInfo } from "@/assets/js/contact";
import { getAxiosData } from "@/assets/js/function";

export default {
    name: "ContactMng",
    components: {BaseButton, TextareaBox, LabelTable, Confirm},
    computed : {
        ...mapState('contact', {
            info : state => state.contact.info,
        })
    },
    data() {
        return {
            inputMemo           : '',
            state               : 'Y',
            resultYn            : 'N',
            compYn              : 'N',
            ikey                : this.$route.params.ikey,
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : ''
        }
    },
    created() {
        this.$store.commit('contact/setReset');
        this.getInfo();
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    this.getResult();
                }

                if(this.state === 'M')
                {
                    this.getMemoResult();
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';

                if(this.state === 'Y')
                {
                    history.back();
                }
                else
                {
                    this.getInfo();
                }
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getBack() {
            this.$router.go(-1);
        },
        getInfo() {
            getContactInfo({ ikey : this.ikey });
        },
        getInputMemo() {
            this.inputMemo = event.target.value;
        },
        getInputVal() {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getResultSet(gb) {
            this.state = gb;

            if(gb === 'Y')
            {
                this.getConfirmSet('confirm', '저장', '답변 등록하시겠습니까?');
                this.resultYn = 'Y';
            }
            else
            {
                this.getConfirmSet('confirm', '저장', '메모를 저장하시겠습니까?');
            }
        },
        async getMemoResult() {
            try
            {
                const params = {
                    code : this.ikey,
                    memo : this.inputMemo
                };

                const res = await getAxiosData('https://data.wecls.com/admin/faq/memoProc', params);

                if(res['status'] === 200)
                {
                    this.inputMemo = '';
                    this.compYn    = 'Y';
                    this.getConfirmSet('alert', '확인', '메모가 저장되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                this.inputMemo = '';
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        },
        async getResult() {
            this.resultYn = 'N';

            try
            {
                const params = {
                    ikey : this.ikey
                };

                const res = await getAxiosData('https://data.wecls.com/admin/faq/compProc', params);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '완료', '답변 완료 처리되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}

.button-wrapper{
    text-align: center;
}
</style>