<template>
    <div class="inner box">
        <h2>기관 회원관리</h2>
        <SortListCard :sorts="headers" class="mt-30" />
        <FilterList :filters="filters" class="mt-80" @filter-changed="getListChange"/>
        <div class="margin grey-box">
            <SelectBox :labelText="'간편 조회'" :selectOptions="getDateType" :selectId="'type'" :selectVal="search['type']" :selectChange="getSelectDate" class="small" />
            <SelectBox :labelText="'상태별 구분'" :selectOptions="expert" :selectId="'expert'" :selectVal="search['expert']" :selectChange="getSelectSet" class="small" />
            <SelectBox :labelText="'가입채널 조회'" :selectOptions="channel" :selectId="'channel'" :selectVal="search['channel']" :selectChange="getSelectSet" class="small" />

            <div class="flex">
                <DateBox labelText="시작일" class="small" :inputId="'startDt'" :inputValue="search['startDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
                <span>~</span>
                <DateBox labelText="종료일" class="small" :inputId="'endDt'" :inputValue="search['endDt']" :selectFunction="getSelectDatePicker" :timePicker="false"/>
            </div>
        </div>
        <BasicTable :columns="tableList" :rows="list" class="margin" @row-click="getDetail"/>
    </div>
</template>

<script>
import SortListCard from "@/components/card/SortListCard.vue";
import FilterList from "@/components/list/FilterList.vue";
import BasicTable from "@/components/table/BasicTable.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import DateBox from "@/components/input/DateBox.vue";

import { mapState } from "vuex";
import { getAgencyMemMngList } from "@/assets/js/agencyMemMng";
import { getDate, getConvertDate } from "@/assets/js/function";

export default {
    name: "AgencyMemMng",
    components: { DateBox, SelectBox, BasicTable, FilterList, SortListCard },
    computed: {
        ...mapState('data', {
            dateType  : state => state.data.dateType,
            expert    : state => state.data.expert,
            channel   : state => state.data.channel
        }),
        ...mapState('agencyMemMng', {
            headers : state => state.agencyMemMng.headers,
            search  : state => state.agencyMemMng.search,
            filters : state => state.agencyMemMng.filters,
            allList : state => state.agencyMemMng.allList,
            list    : state => state.agencyMemMng.list
        }),
        getDateType() {
            const data = [{ value : '', name : '전체' }];

            return data.concat(this.dateType);
        }
    },
    created() {
        getAgencyMemMngList(this.getParams());
    },
    data() {
        return {
            tableList: [
                { key: "platform", header: "플랫폼", class: "w8" },
                { key: "email", header: "아이디", class: "blue" },
                { key: "channel", header: "가입채널", class: "w8" },
                { key: "name", header: "가입자명", class: "w8" },
                { key: "activeGbNm", header: "활동상태", class: "w8" },
                { key: "stNm", header: "전문가 등록", class: "w8" },
                { key: "modDt", header: "최근 심사일", class: "w9" },
                { key: "regDt", header: "가입일", class: "w9" },
                { key: "recLogin", header: "최근 로그인", class: "w9" },
                { key: "loginCnt", header: "로그인 횟수", class: "w8" },
            ]
        }
    },
    methods : {
        getSelectDate(id, event) {
            this.search[id] = event;

            const value = event['value'];

            if(value !== '')
            {
                const date  = getDate(value);

                this.search['startDt'] = getConvertDate(date['st'], 'yyyy-mm-dd');
                this.search['endDt']   = getConvertDate(date['et'], 'yyyy-mm-dd');
            }

            getAgencyMemMngList(this.getParams());
        },
        getSelectDatePicker(date, id) {
            this.search[id] = date;

            getAgencyMemMngList(this.getParams());
        },
        getSelectSet(id, event) {
            this.search[id] = event;

            getAgencyMemMngList(this.getParams());
        },
        getListChange(params) {
            if(params['title'] === '전체')
            {
                this.$store.commit('agencyMemMng/setList', this.allList);
            }
            else
            {
                const list = this.allList.map((item) => {
                    if(item.gbNm === params['title'])
                    {
                        return item;
                    }
                });

                this.$store.commit('agencyMemMng/setList', list);
            }
        },
        getDetail(params) {
            const oaCd = params['oaCd'];

            this.$router.push({ path : `/agencyInfo/${oaCd}`});
        },
        getParams(){
            const params = {
                st      : this.search['expert']['value'],
                start   : 0,
                limit   : 50
            }
            
            if(this.search['type']['value'] !== '')
            {
                params['startDt']   = this.search['startDt'];
                params['endDt']     = this.search['endDt'];
            }

            return params;
        }
    }
}
</script>

<style lang="scss" scoped>
.mt-80{
    margin-top: 80px;
}

.margin{
    margin-top: 46px;
}
</style>