<template>
    <table class="label-table">
        <colgroup>
            <col class="w13"/>
            <col class="w87"/>
        </colgroup>

        <thead>
        <tr>
            <th colspan="2">{{ title }}</th>
        </tr>
        </thead>

        <tbody>
        <slot></slot>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "LabelTable",
    props: {
        title: String,
    }
}
</script>

<style lang="scss">
.label-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.20);

    &.no-shadow{
        box-shadow: none;
    }

    thead {
        font-size: $font16;
        font-weight: 700;

        tr {
            th {
                text-align: left;
                padding: 14px 20px;
                background-color: $bk-color-lv-3;
            }
        }
    }

    tbody {
        font-size: $font14;

        tr {
            th, td {
                padding: 20px 12px;
                font-weight: 400;
                border: 1px solid $line-color-lv-2;
                position: relative;

                div{
                    display: flex;
                }

                &.p12{
                    padding: 12px;
                }
            }

            th {
                background-color: $table-color-lv-1;

                span{
                    font-size: $font10;
                    display: block;
                    color: $text-color-lv-3;

                    &.important{
                        display: inline;
                        color: $red-color-lv-2;
                        vertical-align: top;
                    }
                }
            }

            td {
                background-color: $bk-color-lv-3;

                & > div{
                    display: flex;
                    align-items: center;
                    p {
                        margin-right: 10px;
                    }
                }

                & > div + div {
                    margin-top: 10px;
                }

                .red{
                    color: $red-color-lv-2;
                }

                .orange{
                    color: $red-color-lv-5;
                }
            }
        }
    }
}
</style>