import { createStore } from 'vuex';
import dataModule from '@/modules/dataModule'; 
import loginModule from '@/modules/loginModule'; 
import agencyMemMngModule from '@/modules/agencyMemMngModule';
import teacherMemMngModule from '@/modules/teacherMemMngModule';
import announceMngModule from '@/modules/announceMngModule';
import calcMngModule from '@/modules/calcMngModule';
import noticeModule from '@/modules/noticeModule';
import contactModule from '@/modules/contactModule';
import alarmModule from '@/modules/alarmModule';
import tosModule from '@/modules/tosModule';
import adverModule from '@/modules/adverModule';

const store = createStore({
    modules: {
        login           :   loginModule,
        data            :   dataModule,
        announceMng     :   announceMngModule,
        agencyMemMng    :   agencyMemMngModule,
        teacherMemMng   :   teacherMemMngModule,
        calcMng         :   calcMngModule,
        notice          :   noticeModule,
        contact         :   contactModule,
        alarm           :   alarmModule,
        tos             :   tosModule,
        adver           :   adverModule
    }
});

export default store;