<template>
    <div class="join intro">
        <MobileBox title="회원가입 2/2" showPrev>
            <PreviewImg labelText="프로필 사진" class="mb-14" />

            <InputBox labelText="강사명" :inputId="'instrNm'" :inputType="'text'"
                      :inputValue="''" :helperText="''" :class="''"
                      placeholder="강사 회사명." important class="mt-30" @input="''"/>

            <InputBox labelText="주소" :inputId="'addr'" inputType="text"
                      :inputValue="''" :helperText="''" :class="''"
                      placeholder="주소를 검색하세요." important readonly class="white" @click="''"/>

            <InputBox labelText="매장 상세주소" :inputId="'addrDetail'" inputType="text"
                      :inputValue="''" :helperText="''" :class="''"
                      placeholder="상세주소를 입력하세요." important @input="''" />

            <InputBox labelText="대표 전화번호" :inputId="'tel'" inputType="text"
                      :inputValue="''" :helperText="''" :class="''"
                      placeholder="대표 전화번호를 입력하세요." important @input="''"/>

            <SelectBox :getOptions="''" labelId="" labelNm="" labelText="서비스 분야" important />
            <div>
                <label for="days">
                    매장운영일
                </label>
                <ul class="input-wrapper">
                    <li>
                        <input type="checkbox" id="mon" name="days" value="월" @change="getDay" :checked="getDayStat('월')"/>
                        <label for="mon">월</label>
                    </li>
                    <li>
                        <input type="checkbox" id="tue" name="days" value="화" @change="getDay" :checked="getDayStat('화')"/>
                        <label for="tue">화</label>
                    </li>
                    <li>
                        <input type="checkbox" id="wen" name="days" value="수" @change="getDay" :checked="getDayStat('수')"/>
                        <label for="wen">수</label>
                    </li>
                    <li>
                        <input type="checkbox" id="thur" name="days" value="목" @change="getDay" :checked="getDayStat('목')"/>
                        <label for="thur">목</label>
                    </li>
                    <li>
                        <input type="checkbox" id="fri" name="days" value="금" @change="getDay" :checked="getDayStat('금')"/>
                        <label for="fri">금</label>
                    </li>
                    <li>
                        <input type="checkbox" id="sat" name="days" value="토" @change="getDay" :checked="getDayStat('토')"/>
                        <label for="sat">토</label>
                    </li>
                    <li class="mr-0">
                        <input type="checkbox" id="sun" name="days" value="일" @change="getDay" :checked="getDayStat('일')"/>
                        <label for="sun">일</label>
                    </li>
                </ul>
            </div>
            <div>
                <label for="times">
                    강의 가능 시간
                </label>
                <vue-timepicker />
            </div>

            <TextareaBox labelText="강사 설명" inputId="" inputNm="" placeholder="강사 설명" />

            <div class="bottom">
                <BaseButton buttonText="동의하고 계속하기" class="grey w-full" @click="''" />
            </div>
        </MobileBox>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="''" @getClose="''"/>
</template>

<script>
import MobileBox from "@/components/common/MobileBox.vue";
import PreviewImg from "@/components/input/PreviewImg.vue";
import InputBox from "@/components/input/InputBox.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

export default {
    name: "JoinSecond",
    components: {BaseButton, TextareaBox, SelectBox, InputBox, PreviewImg, MobileBox, Confirm},
    data() {
        return {
            worktime : [
                { value : '09:00 ~ 18:00', name : '09:00 ~ 18:00' },
            ],
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
            joinStat            : 'N'
        }
    },
}
</script>

<style lang="scss" scoped>
.join{
    .mobile-content{
        div + div, div + button {
            margin-top: 24px;
        }

        .input-wrapper{
            width: 100%;
            position: relative;
            border-radius: 4px;
            background-color: $bk-color-lv-3;
            border: 1px solid $line-color-lv-3;
            padding: 26px;
            text-align: center;

            li{
                margin-right: 8.5px;
                display: inline-block;
                input{
                    display: none;

                    &:checked{
                        & + label{
                            background-color: $blue-color-lv-4;
                            color: $bk-color-lv-3;
                            border: 1px solid $blue-color-lv-4;
                        }
                    }
                }

                label{
                    padding: 12px 8px;
                    border-radius: 20px;
                    border: 1px solid $line-color-lv-1;
                    cursor: pointer;
                    font-size: $font12;
                    font-weight: 700;
                }
            }
        }
    }

    .bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 8px 16px 14px 16px;
        @include shadow2;
        width: 100%;
        background-color: $bk-color-lv-3;
        z-index: 2;
    }
}
</style>