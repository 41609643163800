import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 강사 회원관리
 * @author 김원명, @date 2023-12-26
 */
const teacherMemMngModule = {
    namespaced  : true,
    state       : {
        teacherMemMng : {
            headers : [
                { title : '이번달 신규가입', count : 0 },
                { title : '이번달 탈퇴', count : 0 },
                { title : '강사 업데이트 대기', count : 0 },
                { title : '강사 업데이트 심사중', count : 0 },
                { title : '강 업데이트 승인', count : 0 }
            ],
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd'),
                expert  : { value : '', name : '전체' },
                channel : { value : '', name : '전체' }
            },
            filters : [
                { title : '일반회원', count : 0 },
                { title : '정지회원', count : 0 },
                { title : '탈퇴회원', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList : [],
            list    : [],
            info    : getTeacherMemMngInfo()
        }
    },
    mutations: {
        setHeaders(state, newHeaders)
        {
            state.teacherMemMng.headers = newHeaders;
        },
        setFilters(state, newFilters)
        {
            state.teacherMemMng.filters = newFilters;
        },
        setAllList(state, newAllList)
        {
            state.teacherMemMng.allList = newAllList;
            state.teacherMemMng.list    = newAllList;
        },
        setList(state, newList)
        {
            state.teacherMemMng.list = newList;
        },
        setInfo(state, newInfo)
        {
            state.teacherMemMng.info = newInfo;
        }
    }
};

function getTeacherMemMngInfo()
{
    return {
        platform    : '강사',
        channel     : '',
        joinDt      : '',
        loginDt     : '',
        id          : '',
        name        : '',
        tel         : '',
        birthday    : '',
        gender      : '',
        agree       : '',
        appeal      : '',
        idPic       : '', /** 증명 사진 */
        emerTel     : '',
        desArea1    : '',
        prefDay     : '',
        acadAbil    : '',
        school      : '',
        major       : '',
        lectField   : '',
        career      : '',
        repCareer   : [],
        finalEdu    : '', /** 최종학력 증명 파일*/
        careerFile  : '', /** 경력 증명서 파일 */
        fileOther   : [],
        pubYn       : '',
        lcCnt       : '',
        matchCnt    : '',
        compCnt     : '',
        compAmt     : '',
        cancleCnt   : '',
        userMemo    : [],
        state       : '',
        lastDt      : ''
    }
}

export default teacherMemMngModule;
