<template>
    <div class="inner box">
        <h2>알림 등록</h2>
        <LabelTable title="기본정보" class="margin">
            <tr>
                <th>알림 제목</th>
                <td class="p12">
                    <InputBox :inputId="'subject'" :inputValue="info['subject']" placeholder="최대 20자 / 예: 서비스 품질 향상을 위한 업데이트" class="semi-regular" @input="getInputVal"/>
                </td>
            </tr>

            <tr>
                <th>회원 구분*</th>
                <td>
                    <SelectBox :selectOptions="alarm" :selectId="'userGb'" :selectVal="info['userGb']" :selectChange="getSelectSet" class="small" />
                </td>
            </tr>

            <!-- <tr>
                <th>등록 담당자</th>
                <td>
                    <InputBox inputId="" inputNm="" class="medium" readonly disabled />
                </td>
            </tr>

            <tr>
                <th>등록일시</th>
                <td>
                    <InputBox inputId="" inputNm="" class="medium" readonly disabled />
                </td>
            </tr> -->

            <tr>
                <th>알림 사유</th>
                <td>
                    <TextareaBox :inputId="'memo'" :inputValue="info['memo']" @input="getInputVal"/>
                </td>
            </tr>
        </LabelTable>

        <div class="white-box mt-10">
            <h3 class="mb-20 mt-10">알림 내용</h3>
            <TextEditor v-model="info['contents']"/>
        </div>

        <div class="button-wrapper mt-10">
            <BaseButton v-if="!notiCd" buttonText="등록하기" class="rounded4 regular" @click="getResultSet('I')"/>
            <!-- <BaseButton v-if="!notiCd" buttonText="삭제하기" class="rounded4 regular orange" @click="getResultSet('D')"/>
            <BaseButton v-if="!notiCd" buttonText="수정하기" class="rounded4 regular" @click="getResultSet('U')"/> -->
        </div>
    </div>
    <Confirm v-if="confirmStat" :type="confirmType" :title="confirmTitle" :description="confirmDescription" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script>
import LabelTable from "@/components/table/LabelTable.vue";
import InputBox from "@/components/input/InputBox.vue";
import SelectBox from "@/components/input/SelectBox.vue";
import TextareaBox from "@/components/input/TextareaBox.vue";
import TextEditor from "@/components/common/TextEditor.vue";
import BaseButton from "@/components/button/BaseButton.vue";
import Confirm from "@/components/alert/Confirm.vue";

import { mapState } from "vuex";
import { getAlarmInfo } from "@/assets/js/alarm";
import { getAxiosData } from "@/assets/js/function";

export default {
    name: "AlarmUpdate",
    components: {BaseButton, TextEditor, TextareaBox, SelectBox, InputBox, LabelTable, Confirm},
    computed: {
        ...mapState('data', {
            alarm : state => state.data.alarm
        }),
        ...mapState('alarm', {
            info : state => state.alarm.info
        })
    },
    data() {
        return {
            state               : 'I',
            resultYn            : 'N',
            compYn              : 'N',
            notiCd              : this.$route.params.notiCd,
            confirmStat         : false,
            confirmType         : 'confirm',
            confirmTitle        : '',
            confirmDescription  : '',
        }
    },
    created() {
        this.$store.commit('alarm/setReset');
        this.getInfo();
    },
    methods : {
        getConfirmResult() {
            this.confirmStat = false;

            if(this.compYn === 'N')
            {
                if(this.resultYn === 'Y')
                {
                    if(this.state !== 'D')
                    {
                        this.getResult();
                    }
                    else
                    {
                        this.getDelete();
                    }
                }
            }

            if(this.compYn === 'Y')
            {
                this.compYn = 'N';
                history.back();
            }
        },
        getConfirmClose() {
            this.confirmStat = false;
        },
        getConfirmSet(type, title, description) {
            this.confirmType        = type;
            this.confirmStat        = true;
            this.confirmTitle       = title;
            this.confirmDescription = description;
        },
        getInfo() {
            if(this.notiCd)
            {
                getAlarmInfo({ notiCd : this.notiCd });
            }
        },
        getSelectSet(id, event) {
            this.info[id] = event;
        },
        getInputVal() {
            const id    = event.target.id;
            const value = event.target.value;

            this.info[id] = value;
        },
        getInputValCheck() {
            if(this.info['subject'] === '')
            {
                this.getConfirmSet('alert', '확인', '알림 제목을 입력하세요.');
                return false;
            }

            if(this.info['contents'] === '')
            {
                this.getConfirmSet('alert', '확인', '알림 내용을 입력하세요.');
                return false;
            }

            return true;
        },
        getResultSet(gb) {
            this.state = gb;

            if(!this.getInputValCheck() && gb !== 'D')
            {
                return false;
            }

            switch(gb)
            {
                case 'I':
                    this.getConfirmSet('confirm', '저장', '등록하시겠습니까?');
                break;
                case 'U':
                    this.getConfirmSet('confirm', '저장', '수정하시겠습니까?');
                break;
                case 'D':
                    this.getConfirmSet('confirm', '삭제', '해당 알림을 삭제하시겠습니까?');
                break;
            }

            this.resultYn = 'Y';
        },
        async getResult() {
            this.resultYn = 'N';

            try
            {
                const params = {
                    subject  : this.info['subject'],
                    contents : this.info['contents'],
                    gb       : 'N',
                    userGb   : this.info['userGb']['value'],
                    memo     : this.info['memo']
                };

                if(this.notiCd)
                {
                    params['notiCd'] = this.notiCd
                }

                const res = await getAxiosData('https://data.wecls.com/admin/notiList/dataProc', params);

                if(res['status'] === 200)
                {
                    this.compYn = 'Y';
                    this.getConfirmSet('alert', '저장', '저장되었습니다.');
                }
            }
            catch(error)
            {
                console.log(error);
                const check = error.response.data.message.includes('NotAuthorizedException');

                if(error.response.data.status === 401 || check)
                {
                    alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
                    this.$store.dispatch('login/logout');
                    this.$router.push('/login');
                }
                else if(error.response.data.status === 400)
                {
                    this.getConfirmSet('alert', '확인', error.response.data.data);
                }
                else
                {
                    alert('오류가 발생했습니다. 지속될 경우 관리자에게 문의하세요.');
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.margin {
    margin-top: 46px;
}

.white-box{
    border-radius: 4px;
    background-color: $bk-color-lv-3;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.20);
    padding: 12px 20px 100px 20px;

    h3{
        font-size: $font16;
    }
}

.button-wrapper{
    text-align: center;
    background-color: $bk-color-lv-3;
    padding: 40px 0;
}
</style>