import { getDate, getConvertDate } from "@/assets/js/function";

const date = getDate('mon');
/**
 * @description 정산 현황
 * @author 김원명, @date 2024-01-05
 */
const calcMngModule = {
    namespaced  : true,
    state       : {
        calcMng : {
            search  : {
                type    : { name : '이달', value : 'mon' },
                startDt : getConvertDate(date['st'], 'yyyy-mm-dd'),
                endDt   : getConvertDate(date['et'], 'yyyy-mm-dd'),
                dep     : { value : '', name : '전체' },
                pay     : { value : '', name : '전체' }
            },
            filters : [
                { title : '입금대기', count : 0 },
                { title : '지급대기', count : 0 },
                { title : '정산완료', count : 0 },
                { title : '전체', count : 0 }
            ],
            allList     : [],
            list        : [],
            organInfo   : getCalcMngOrganInfo(),
            instInfo    : getCalcMngInstInfo()
        }
    },
    mutations: {
        setFilters(state, newFilters)
        {
            state.calcMng.filters = newFilters;
        },
        setAllList(state, newAllList)
        {
            state.calcMng.allList = newAllList;
            state.calcMng.list    = newAllList;
        },
        setList(state, newList)
        {
            state.calcMng.list = newList;
        },
        setOrganInfo(state, newOrganInfo)
        {
            state.calcMng.organInfo = newOrganInfo;
        },
        setInstInfo(state, newInstInfo)
        {
            state.calcMng.instInfo = newInstInfo;
        }
    }
};

function getCalcMngOrganInfo()
{
    return {
        lcInfo      : [],
        payDt       : '',
        totalAmt    : '',
        couponNm    : '',
        couponAmt   : '',
        point       : {
            own : '',
            use : ''
        },
        calcAmt     : '',
        payGb       : '', /** 계좌이체 : 'A' / 신용/체크카드 : 'C' */
        bankNm      : '',
        bankAcc     : '',
        payDoc      : '', /** 세금계산서 : 'T' / 현금영수증 : 'C' */
        einNm       : '',
        ceoNm       : '',
        einNum      : '',
        einItem     : '',
        einType     : '',
        addr        : '',
        email       : ''
    }
}

function getCalcMngInstInfo()
{
    return {
        lcInfo      : [],
        calcGb      : 'N', /** N : '일반정산' / D: '데이페이' */
        totalAmt    : '',
        charge      : '',
        chargeAmt   : '',
        calcAmt     : '',
        calcMean    : '계좌이체',
        bankNm      : '',
        bankAcc     : '',
        bankNum     : '',
        docEnum     : 'T', /** T : '세금계산서' / C : '사업소득세 3.3%' */
        einNm       : '',
        einNum      : '',
        einType     : '',
        einItem     : '',
        ceoNm       : '',
        addr        : '',
        email       : '',
        cashNum     : '',
        stat        : 'N'
    }
}

export default calcMngModule;
