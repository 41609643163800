<template>
    <button class="button">
        {{ buttonText }}
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        buttonText : String,
    }
}
</script>

<style lang="scss" scoped>
.button{
    padding: 8px 20px;
    border-radius: 2px;
    color: $bk-color-lv-3;
    background-color: $blue-color-lv-4;
    font-size: $font14;
    font-weight: 500;
    min-width: 95px;

    &.grey{
        color: $text-color-lv-0;
        background-color: $button-color-lv-1;
    }

    &.orange{
        background-color: $red-color-lv-5;
    }

    &.rounded4{
        border-radius: 4px;
    }

    &.font16{
        font-size: $font16;
    }

    &.font20{
        font-size: $font20;
    }

    &.weight7{
        font-weight: 700;
    }

    &.mx-right{
        @include my-auto;
    }

    &.p10{
        padding: 10px;
    }

    &.regular{
        width: 120px;
    }

    &.medium{
        width: 146px;
    }

    &.large{
        font-size: $font20;
        font-weight: 700;
        padding: 15px 10px;
        width: 260px;
        border-radius: 4px;
    }
}

.button + .button{
    margin-left: 16px;
}
</style>